import HttpProxy from '@/services/HttpProxy';

export default class ExpenseService {
  static async create(data) {
    const response = await HttpProxy.submit({ method: 'post', url: 'Expenses', data });
    return response?.data ?? null;
  }

  static async getAll(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'Expenses', parameters });
    return response?.data ?? null;
  }
  
  static async delete(id) {
    const response = await HttpProxy.submit({ method: 'delete', url: `Expenses/${id}` });
    return response?.data ?? null;
  }

  static async getById(id) {
    const response = await HttpProxy.submit({ method: 'get', url: `Expenses/${id}` });
    return response?.data ?? null;
  }

  static async update(data) {
    const response = await HttpProxy.submit({ method: 'put', url: `Expenses/${data.id}`, data });
    return response?.data ?? null;
  }

  static async quote(data) {
    const response = await HttpProxy.submit({ method: 'post', url: 'Expenses/quote', data });
    return response?.data ?? null;
  }

  static async pay(data) {
    const response = await HttpProxy.submit({ method: 'post', url: `Expenses/${data.expenseId}/pay`, data });
    return response?.data ?? null;
  }
}