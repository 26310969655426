import {
  BUSINESS_READ,
  BUSINESS_UPDATE,
  BUSINESS_DESTROY,
  BUSINESS_EMPLOYEES,
  BUSINESS_OFFICES,
  BUSINESS_NOTIFICATIONS,
} from './mutation-types';

export default {
  [BUSINESS_READ](state, data) {
    state.id = data.id;
    state.address = data.address;
    state.businessId = data.businessId;
    state.country = data.country;
    state.logo = data.logo;
    state.name = data.name;
    state.phone = data.phone;
    state.currency = data.currency;
    state.timezone = data.timezone;
    state.phoneCountryCodeId = data.phoneCountryCodeId;
    state.dateFormat = data.dateFormat;
  },
  [BUSINESS_UPDATE](state, data) {
    state.id = data.id;
    state.address = data.address;
    state.businessId = data.businessId;
    state.country = data.country;
    state.logo = data.logo;
    state.name = data.name;
    state.phone = data.phone;
    state.currency = data.currency;
    state.timezone = data.timezone;
    state.phoneCountryCodeId = data.phoneCountryCodeId;
    state.dateFormat = data.dateFormat;
  },
  [BUSINESS_EMPLOYEES](state, data) {
    state.employees = data.employees;
  },
  [BUSINESS_OFFICES](state, data) {
    state.offices = data.offices;
  },
  [BUSINESS_NOTIFICATIONS](state, data) {
    state.notifications = data.notifications;
  },
  [BUSINESS_DESTROY](state) {
    state.id = null;
    state.address = null;
    state.businessId = null;
    state.country = null;
    state.logo = null;
    state.name = null;
    state.phone = null;
    state.currency = null;
    state.timezone = null;
    state.phoneCountryCodeId = null;
    state.dateFormat = null;
    state.employees = [];
  }
};
