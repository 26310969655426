import Vue from 'vue';
import lang from 'element-ui/lib/locale/lang/es';
import locale from 'element-ui/lib/locale';
import Toastr from 'vue-toastr';
import SweetAlert from 'vue-sweetalert2';
import HighchartsVue from 'highcharts-vue';
import VueYoutube from 'vue-youtube';
import VueSignature from 'vue-signature-pad';

import {
  // Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  DatePicker,
  TimePicker,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Upload,
  Tabs,
  TabPane,
  Alert,
  // Upload,
  Spinner,
  // Image,
  Loading,
  Message,
  MessageBox,
  Notification,
  Steps,
  Step,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  Card,
  Descriptions,
  DescriptionsItem,
  Row,
  Col,
  Drawer,
  Badge,
} from 'element-ui';

// Vue.use(ElementUI)

// Vue.use(Pagination)
Vue.use(Badge);
Vue.use(Drawer);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch)
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Upload);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Alert);
// Vue.use(Upload)
Vue.use(Spinner);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Collapse);
Vue.use(CollapseItem);
// Vue.use(Image)
Vue.use(Toastr, {
  defaultPosition: 'toast-bottom-right',
});
Vue.use(Timeline)
Vue.use(TimelineItem);
Vue.use(SweetAlert);
Vue.use(Loading.directive);
Vue.use(HighchartsVue);
Vue.use(Card);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);

Vue.prototype.$ELEMENT = { size: 'medium' };
Vue.prototype.$loading = Loading.service;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition);

locale.use(lang);

const moment = require('moment');
require('moment/locale/es');

Vue.use(require('vue-moment'), {
  moment
});

Vue.use(VueYoutube);

Vue.use(VueSignature);
