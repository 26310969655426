import HttpProxy from '@/services/HttpProxy';
import store from '@/store';
import CategoryService from "@/services/CategoryService";

export default class CatalogService {
  static async getAllDeviceModels(queryParams) {
    const apiResponse = await HttpProxy.submit({
      method: 'get',
      url: 'catalogs/device-models',
      parameters: { ...queryParams }
    });
    return apiResponse?.data ?? null;
  }

  static async getDeviceModelById(id) {
    const apiResponse = await HttpProxy.submit({ method: 'get', url: `catalogs/device-models/${id}` });
    return apiResponse?.data ?? null;
  }

  static async createDeviceModel(payload) {
    const apiResponse = await HttpProxy.submit({ method: 'post', url: 'catalogs/device-models', data: payload });
    return apiResponse?.data ?? null;
  }

  static async getTimeZones() {
    const apiResponse = await HttpProxy.submit({ method: 'get', url: `businessInfo/timezones` });
    return apiResponse?.data ?? null;
  }

  static async syncCatalogs() {
    this.getFlags();
    this.getPaymentMethods();
    this.getOrderStatuses();
    this.getBusinessTaxes();
    this.getExpensesSubcategories();
    this.getProductCategories();
    this.getOrderServiceCategories();
  }

  static async getExpensesSubcategories(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'ExpenseCategories', parameters });
    if (!response?.data) {
      return null;
    }
    store.dispatch('expenses/store',
      {
        ...store.state.expenses,
        subcategories: response.data
          .map((subcategory) => ({
            id: subcategory.id,
            name: subcategory.name,
            category: subcategory.category,
          })),
      });
  }

  static async getFlags(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'Countries', parameters });
    if (!response?.data) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      flags: response.data
        .map((flag) => ({
          id: flag.id,
          name: flag.name,
          isoCode: flag.isoCode,
          countryCode: flag.countryCode,
        })),
    });
  }

  static async getPaymentMethods(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'PaymentMethod', parameters });
    if (!response?.data) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      paymentMethods: response.data
        .map((paymentMethod) => ({
          id: paymentMethod.id,
          name: paymentMethod.name,
        })),
    });
  }

  static async getOrderStatuses(parameters) {
    const response = await HttpProxy.submit({ method: 'get', url: 'OrderStatus', parameters });
    if (!response?.data) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      order: {
        ...store.state.catalogs.order,
        statuses: response.data
          .map((status) => ({
            id: status.id,
            name: status.name,
            color: status.colour || '#000000',
            order: status.order,
            isActive: status.isActive,
            isEditable: status.isEditable,
            isDeletable: status.isDeletable,
            parentOrderStatusId: status.parentOrderStatusId,
          }))
          .sort((a, b) => a.order - b.order)
      },
    });
  }

  static async postOrderStatus(data) {
    const response = await HttpProxy.submit({ method: 'post', url: 'OrderStatus', data: data });
    if (!response?.data) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      order: {
        statuses: [
          ...store.state.catalogs.order.statuses,
          {
            id: response.data.id,
            name: data.name,
            color: data.colour,
            order: data.order,
            isActive: true,
            isEditable: true,
            isDeletable: true,
            parentOrderStatusId: data.parentOrderStatusId,
          },
        ].sort((a, b) => a.order - b.order),
      },
    });
    return response.data;
  }

  static async putOrderStatus(data) {
    const response = await HttpProxy.submit({ method: 'put', url: `OrderStatus/${data.id}`, data: data });
    if (!response?.data) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      order: {
        statuses: store.state.catalogs.order.statuses
          .map((orderStatus) => (orderStatus.id === data.id ? ({
            id: response.data.id,
            name: response.data.name,
            color: response.data.colour,
            order: response.data.order,
            isActive: response.data.isActive,
            isEditable: response.data.isEditable,
            isDeletable: response.data.isDeletable,
            parentOrderStatusId: response.data.parentOrderStatusId,
          }) : orderStatus))
          .sort((a, b) => a.order - b.order),
      },
    });
  }

  static async deleteOrderStatus(id) {
    const response = await HttpProxy.submit({ method: 'delete', url: `OrderStatus/${id}` });
    if (!response?.data) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      order: {
        statuses: store.state.catalogs.order.statuses
          .filter((orderStatus) => orderStatus.id !== id)
          .sort((a, b) => a.order - b.order),
      },
    });
  }

  static async getBusinessTaxes() {
    const response = await HttpProxy.submit({ method: 'get', url: 'BusinessTax' });
    if (!response?.data) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      businessTaxes: response.data
        .map((businessTax) => ({
          id: businessTax.id,
          name: businessTax.name,
          percentage: businessTax.percentage,
        })),
    });
  }

  static async getProductCategories() {
    const categories = await CategoryService.Product.get({});
    if (!categories) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      product: {
        ...store.state.catalogs.product,
        categories: categories.map(
          (category) => ({
            id: category.id,
            name: category.name,
          }),
        ),
      }
    });
  }

  static async getOrderServiceCategories() {
    const categories = await CategoryService.Service.get({});
    if (!categories) {
      return null;
    }
    store.dispatch('catalogs/store', {
      ...store.state.catalogs,
      order: {
        ...store.state.catalogs.order,
        service: {
          ...store.state.catalogs.order.service,
          categories: categories.map(
            (category) => ({
              id: category.id,
              name: category.name,
            }),
          ),
        },
      },
    });
  }
}
