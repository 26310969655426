<template>
  <el-form :model="form" :rules="rules" ref="productForm" v-loading="loading">
    <content-card class="mb-6">
      <div slot="title" class="w-full inline-flex justify-between mb-6">
        <strong>Categoría</strong>
        <a class="text-base text-purple-400" @click="showCategoryCreate">
          <span>+ Agregar nueva categoria</span>
        </a>
      </div>
      <div class="w-full inline-flex">
        <el-form-item prop="category" class="w-full pr-2">
          <el-input
            type="text"
            v-model="textSearch"
            @input="showCategorySearch(true)"
          >
            <i slot="prefix" class="mt-2 text-xl bx bx-search" />
          </el-input>
        </el-form-item>
      </div>
    </content-card>
    <content-card class="mb-6" title="Producto">
      <div class="w-full inline-flex">
        <el-form-item prop="name" class="w-full pr-2">
          <strong>Nombre</strong>
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item prop="color" class="w-full pr-2">
          <strong>Color</strong>
          <el-input v-model="form.color" />
        </el-form-item>
      </div>
      <div class="w-full inline-flex">
        <el-form-item prop="details" class="w-full pr-2">
          <strong>Detalles</strong>
          <el-input v-model="form.details" />
        </el-form-item>
        <el-form-item prop="supplier" class="w-full pr-2">
          <strong>Proveedor</strong>
          <el-select class="w-full" v-model="form.supplier">
            <el-option
              v-for="supplier of suppliers"
              :key="supplier.id"
              :value="supplier.id"
              :label="supplier.company"
            >
              <div class="flex flex-row justify-between">
                {{ supplier.company }}
                <span class="text-sm">{{ supplier.contactEmail }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item prop="description" class="w-full pr-2">
        <strong>Descripción</strong>
        <el-input type="textarea" :rows="3" v-model="form.description" />
      </el-form-item>
    </content-card>
    <content-card class="mb-6" title="Precio">
      <div class="w-full inline-flex">
        <el-form-item
          prop="cost"
          class="w-full pr-2"
          v-if="$store.state.account.canEditOrdersPrices"
        >
          <span class="inline-flex items-center justify-center flex-shrink-0">
            Costo
          </span>
          <el-input type="number" v-model="form.cost" />
        </el-form-item>
        <el-form-item prop="price" class="w-full pl-2">
          <span class="inline-flex items-center justify-center flex-shrink-0">
            Precio de venta
          </span>
          <el-input type="number" v-model="form.price" />
        </el-form-item>
      </div>
    </content-card>
    <content-card class="mb-6" title="Inventario" v-if="showStockQuantities">
      <div class="w-full">
        <div class="w-full" v-if="form.lastPurchasedAt">
          <div class="text-right">
            Ultima Compra: {{ dateFormat(form.lastPurchasedAt) }}
          </div>
        </div>
        <div class="w-full inline-flex">
          <el-form-item prop="quantity" class="w-full pr-2">
            <span class="inline-flex items-center justify-center flex-shrink-0">
              Existencias
            </span>
            <el-input
              type="number"
              v-model="form.quantity"
              :disabled="!$store.state.account.canEditStockQuantities"
            />
          </el-form-item>
          <el-form-item prop="location" class="w-full pr-2">
            <strong>Ubicacion</strong>
            <el-input v-model="form.location" />
          </el-form-item>
        </div>
        <div
          class="w-full inline-flex"
          v-if="$store.state.account.accountType === AccountTypeEnum.Owner"
        >
          <el-form-item prop="minStockAlert" class="w-1/2 pr-2 ">
            <span class="inline-flex items-center justify-center flex-shrink-0">
              Alerta por Stock Minimo
              <pro-link v-if="!isProMembership" />
            </span>
            <el-input type="number" min="0" v-model="form.minStockAlert" :disabled="!isProMembership" />
          </el-form-item>
          <el-form-item prop="minStockAlert" class="w-1/2">
            <span class="inline-flex items-center justify-center flex-shrink-0">
              Alerta por Stock Maximo
              <pro-link  v-if="!isProMembership"/>
            </span>
            <el-input type="number" min="0" v-model="form.maxStockAlert"  :disabled="!isProMembership" />
          </el-form-item>
        </div>
      </div>
    </content-card>
    <el-dialog
      :visible.sync="visibleCategorySearch"
      :width="width"
      append-to-body
    >
      <div>
        <div class="w-full flex flex-row px-6 py-4">
          <el-input v-model="textSearch" ref="textSearch">
            <i slot="prefix" class="mt-2 text-xl bx bx-search" />
          </el-input>
        </div>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="category in categories" :key="category.id">
              <td>
                <el-checkbox
                  :value="selectedCategory === category.id"
                  @change="chooseCategory(category.id)"
                >
                  {{ category.name }}
                </el-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex flex-row justify-end space-x-2">
          <el-button
            class="el-button--default"
            @click="showCategorySearch(false)"
          >
            Cancelar
          </el-button>
          <el-button
            class="el-button--primary"
            :disabled="!selectedCategory"
            @click="selectCategory()"
          >
            Seleccionar
          </el-button>
        </div>
      </div>
    </el-dialog>
  </el-form>
</template>
<style scoped>
.upload-button {
  background: white;
  border: 1px dashed #c0c4c3;
  color: #c0c4c3;
  width: 4rem;
  height: 4rem;
}

.upload-button:hover {
  border: 1px dashed black;
  color: black;
}

.upload-button:disabled {
  border: 1px dashed #c0c4c3;
  color: #c0c4c3;
}
</style>
<script>
import CategoryDialogService, {
  command as CategoryDialogCommand,
  commands,
} from "@/components/dialogs/Category/service";
import CategoryEnum from "@/components/dialogs/Category/enum";
import CategoryService from "@/services/CategoryService";
import CatalogService from "@/services/CatalogService";
import _ from "lodash";
import error from "@/mixins/error";
import responsiveSize from "@/mixins/responsiveSize";
import { AccountTypeEnum } from "@/constants";
import SupplierService from "@/services/SupplierService";

export default {
  name: "ProductForm",
  props: {
    loading: Boolean,
    form: {
      name: String,
      description: String,
      category: Object,
      brand_model: String,
      cost: Number,
      price: Number,
      quantity: Number,
      image: String,
      minStockAlert: Number,
      maxStockAlert: Number,
    },
    hideQuantityControls: {
      type: Boolean,
      default: () => false,
    },
    reset: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [error, responsiveSize],
  components: {
    ContentCard: () => import("@/components/molecules/ContentCard"),
    ProLink: () => import('@/components/atoms/SaProLink.vue'),
  },
  data() {
    return {
      isProMembership: this.$store.state.account.membershipType === 2 || this.$store.state.account.membershipType === 3,
      CategoryEnum,
      errorMessage: undefined,
      show: false,
      rules: {},
      visibleCategorySearch: false,
      categories: [],
      width: "40%",
      textSearch: null,
      selectedCategory: null,
      subscription: null,
      AccountTypeEnum,
      suppliers: [],
    };
  },
  created() {
    this.subscription = CategoryDialogCommand.subscribe(this.customCommands);
    window.addEventListener("resize", _.debounce(this.resize, 300));
  },
  destroyed() {
    this.subscription.unsubscribe();
    window.removeEventListener("resize", _.debounce(this.resize, 300));
  },
  mounted() {
    setTimeout(() => {
      this.textSearch = this.$props.form.category?.name;
      if (this.suppliers.length === 0) this.loadSuppliers();
    }, 1000);
  },
  computed: {
    showStockQuantities() {
      return (
        this.$store.state.account.canEditStockQuantities &&
        !this.hideQuantityControls
      );
    },
  },
  watch: {
    "$props.reset": {
      deep: true,
      handler: _.debounce(async function () {
        this.textSearch = null;
      }, 300),
    },
  },
  methods: {
    customCommands({ type, payload }) {
      switch (type) {
        case commands.create:
          this.categoryCreate(payload);
          break;
        default:
      }
    },
    resize() {
      const percentage = this.getPercentageWidth();
      this.width = percentage === "30%" ? "40%" : percentage;
    },
    showCategoryCreate() {
      CategoryDialogService.show({ type: CategoryEnum.Product });
    },
    async categoryCreate(category) {
      this.form.category = category.id;
      this.textSearch = category.name;
      await CatalogService.getProductCategories();
    },
    chooseCategory(categoryId) {
      this.selectedCategory =
        this.selectedCategory === categoryId ? null : categoryId;
    },
    selectCategory() {
      if (!this.selectedCategory) return;
      const category = this.categories.find(
        (c) => c.id === this.selectedCategory
      );
      this.form.category = category.id;
      this.textSearch = category.name;
      this.visibleCategorySearch = false;
      this.categories = [];
    },
    categoryChange(category) {
      this.form.category = category;
      this.form.brand_model = undefined;
    },
    showCategorySearch(value) {
      this.visibleCategorySearch = value;
      if (value) this.loadCategories();
      if (!value) this.textSearch = null;
    },
    async loadCategories() {
      try {
        this.categories = await CategoryService.Product.get({
          textSearch: this.textSearch,
        });
      } catch (e) {
        this.errorMessage = e;
      }
    },
    async loadSuppliers() {
      try {
        this.suppliers = await SupplierService.getAll({
          businessInfoId: this.$store.state.business.id,
        });
      } catch (e) {
        this.errorMessage = e;
      }
    },
    dateFormat(value) {
      const format =
        this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
      return this.$moment(value).format(format);
    },
  },
};
</script>
