import HttpProxy from '@/services/HttpProxy';

export default class SupplierService {
  static async getAll(parameters) {
    const apiResponse = await HttpProxy.submit({
      method: 'get',
      url: 'suppliers',
      parameters
    });
    return apiResponse.data ?? null;
  }

  static async getbyId(id) {
    const apiResponse = await HttpProxy.submit({ method: 'get', url: `suppliers/${id}` });
    return apiResponse.data ?? null;
  }

  static async create(payload) {
    const apiResponse = await HttpProxy.submit({ method: 'post', url: 'suppliers', data: payload });
    return apiResponse.data ?? null;
  }
  
  static async update(id, payload) {
    const apiResponse = await HttpProxy.submit({ method: 'put', url: `suppliers/${id}`, data: payload });
    return apiResponse.data ?? null;
  }

  static async remove(id) {
    const apiResponse = await HttpProxy.submit({ method: 'delete', url: `suppliers/${id}` });
    return apiResponse.data ?? null;
  }
}