<template>
  <div>
    <div class="w-full">
      <el-form :model="formData" label-position="right" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="Monto">
              <el-input v-model="formData.amount" disabled></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Pagado Hoy">
              <el-checkbox v-model="formData.isExpenseSettled" @change="formData.date = null"></el-checkbox>
            </el-form-item>
            <el-form-item label="Programar Fecha" v-if="!formData.isExpenseSettled">
              <el-date-picker type="date" placeholder="Fecha" v-model="formData.date"
                style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="w-full">
      <inventory-expense-form
        v-for="(formData, index) in formList" :key="index"
        :formData="formData"
        :index="index"
        :active="active"
        @on:product:change="handleProductChange"
      ></inventory-expense-form>
    </div>
    <div class="flex justify-center items-center mt-4 space-x-2">
      <a
        v-for="(formData, index) in formList"
        :key="index"
        @click="setCurrentIndex(index)"
        :class="{
          'text-3xl' : active === index,
          'text-red-500': formData.hasError,
          'text-green-500': !formData.hasError,
        }"
        class="text-lg cursor-pointer"
      >&bull;</a>
      <a class="text-purple-400 ml-auto" @click="addNewForm">
        <strong>+ Agregar Otro Producto</strong>
      </a>
    </div>
  </div>  
</template>
<script>
import _ from "lodash";
import InventoryExpenseForm from './InventoryExpenseForm.vue';
import { isProductFormValid } from '../utils';

export default {
  name: 'InventoryExpenseFormList',
  props: {
    active: {
      type: Number,
      default: () => 0,
    },
    formList: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    InventoryExpenseForm,
  },
  mounted() {
    if (_.isNil(this.formData.id)) {
      this.addNewForm();
    }
    
  },
  methods: {
    addNewForm() {
      const canAddNewForm = _.isEmpty(this.formList) || this.formList.every((form) => !form.hasError);

      if (canAddNewForm) {
        this.formList.push({
          category: null,
          subcategory: {
            id: null,
            name: '',
          },
          observations: '',
          reference: '',
          amount: 0,
          quantity: 0,
          date: null,
          products: [],
          addObservations: false,
          addReference: false,
          cost: 0,
          hasError: true,
          isExpenseSettled: true,
        });
        this.$emit('on:active:update', this.formList.length - 1);
      } else {
        this.$toastr.e('Tienes algun error en productos');
      }
    },
    setCurrentIndex(index) {
      this.$emit('on:active:update', index);
    },
    handleProductChange() {
      this.formData.amount = this.formList.reduce((acc, curr) => {
        if (Number.isNaN(parseFloat(curr.cost))) return acc;
        return acc + (parseFloat(curr.cost) * curr.quantity);
      }, 0);

      this.resolveFormIssues();
    },
    resolveFormIssues() {
      for (let i = 0; i < this.formList.length; i++) {
        this.formList[i].hasError = !isProductFormValid(this.formList[i]);
      }
    },
  }
}
</script>