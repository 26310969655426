import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import account from './modules/account';
import business from './modules/business';
import notifications from './modules/notifications';
import checklistItems from './modules/checklist-items';
import catalogs from './modules/catalogs';
import quickStart from './modules/quick-start';
import invoices from './modules/invoices';
import expenses from './modules/expenses';
import featureFlags from './modules/feature-flags';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    account,
    business,
    notifications,
    checklistItems,
    catalogs,
    quickStart,
    invoices,
    expenses,
    featureFlags,
  },
  strict: process.env.NODE_ENV !== 'production'
});
