<template>
    <div class="p-4">
        <table class="table-auto w-full border-collapse border-none border-gray-300">
            <thead>
                <tr>
                    <!-- Celda A1 evitada con colspan="0" y oculta -->
                    <th></th>

                    <!-- Celdas B1, C1, D1 más grandes con clases de Tailwind -->
                    <th class="px-4 py-2 border border-gray-300 w-1/4">B1</th>
                    <th class="px-4 py-2 border border-gray-300 w-1/4">C1</th>
                    <th class="px-4 py-2 border border-gray-300 w-1/4">D1</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <!-- Celdas A2, B2, C2, D2 -->
                    <td class="px-4 py-2 border border-gray-300">A2</td>
                    <td class="px-4 py-2 border border-gray-300">B2</td>
                    <td class="px-4 py-2 border border-gray-300">C2</td>
                    <td class="px-4 py-2 border border-gray-300">D2</td>
                </tr>
                <tr>
                    <!-- Celdas A3, B3, C3, D3 -->
                    <td class="px-4 py-2 border border-gray-300">A3</td>
                    <td class="px-4 py-2 border border-gray-300">B3</td>
                    <td class="px-4 py-2 border border-gray-300">C3</td>
                    <td class="px-4 py-2 border border-gray-300">D3</td>
                </tr>
                <!-- Más filas según sea necesario -->
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "CustomTable",
};
</script>

<style scoped>
/* Estilos adicionales si es necesario */
</style>