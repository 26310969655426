import {
  CHECKLIST_ITEMS_STORE,
  CHECKLIST_ITEMS_CREATE,
  CHECKLIST_ITEMS_UPDATE,
  CHECKLIST_ITEMS_DELETE,
  CHECKLIST_ITEMS_DESTROY,
} from './mutation-types';

export default {
  [CHECKLIST_ITEMS_STORE](state, data) {
    state.entities = data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  },
  [CHECKLIST_ITEMS_CREATE](state, data) {
    state.entities = [...state.entities, data];
  },
  [CHECKLIST_ITEMS_UPDATE](state, data) {
    state.entities = state.entities.map((entity) => entity.id === data.id ? data : entity);
  },
  [CHECKLIST_ITEMS_DELETE](state, data) {
    state.entities = state.entities.filter((entity) => entity.id !== data);
  },
  [CHECKLIST_ITEMS_DESTROY](state) {
    state.entities = [];
  },
};
