const moment = require('moment');
require('moment/locale/es');

const addDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
};

const getFirstDayOfWeek = () => {
  const today = new Date();
  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - today.getDay());
  return firstDayOfWeek;
};

const getLastDayOfWeek = () => {
  const today = new Date();
  const lastDayOfWeek = new Date(today);
  lastDayOfWeek.setDate(today.getDate() + (6 - today.getDay()));
  return lastDayOfWeek;
};

const getFirstDayOfMonth = () => {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return firstDayOfMonth;
};

const getLastDayOfMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() + 1, 0);
};

const getFirstDayOfYear = () => {
  const today = new Date();
  return new Date(today.getFullYear(), 0, 1);
};

const getLastDayOfYear = () => {
  const today = new Date();
  return new Date(today.getFullYear(), 11, 31);
};

const getSamiiFirstYear = () => {
  return new Date('01/01/2022');
};

const getSamiiLastYear = () => {
  return new Date('12/31/2024');
};

const toFormattedString = (date, format = 'MM/DD/YYYY') => {
  return moment(date).format(format);
};

export default {
  addDays,
  getFirstDayOfWeek,
  getFirstDayOfMonth,
  getFirstDayOfYear,
  getLastDayOfWeek,
  getLastDayOfMonth,
  getLastDayOfYear,
  getSamiiFirstYear,
  getSamiiLastYear,
  toFormattedString,
};
