export const BUSINESS_READ = 'business/read';
export const BUSINESS_UPDATE = 'business/update';
export const BUSINESS_DESTROY = 'business/destroy';
export const BUSINESS_EMPLOYEES = 'business/employees';
export const BUSINESS_OFFICES = 'business/offices';
export const BUSINESS_NOTIFICATIONS = 'business/notifications';

export default {
  BUSINESS_READ,
  BUSINESS_UPDATE,
  BUSINESS_DESTROY,
  BUSINESS_EMPLOYEES,
  BUSINESS_OFFICES,
  BUSINESS_NOTIFICATIONS,
};
