export default {
  id: null,
  email: null,
  fullname: null,
  accountType: null,
  businessId: null,
  businessName: null,
  setupCompleted: false,
  currencySymbol: '$',
  branchOffices: [],
  // MEMBERSHIP
  membershipType: null,
  membershipEndDate: null,
  membershipLastPay: {
    createdAd: null,
    object: null,
    amount: null,
    currency: null,
    succeeded: false,
  },
  membershipAboutToEnd: null,
  membershipLimitReached: false,
  // PERMITS
  canViewAdministration: false,
  canViewBranchOffices: false,
  canViewStatistics: false,
  canViewOrders: false,
  canEditOrders: false,
  canEditOrdersPrices: false,
  canEditStockQuantities: false,
  canCreateOrders: false,
  canDeleteOrders: false,
  canCloseBusiness: false,
}
