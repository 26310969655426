<template>
  <el-form :model="formData" label-position="right" label-width="120px">

    <el-row>
      <el-col :span="12" class="text-right">
        <a class="text-purple-400">
          <strong>&nbsp;</strong>
        </a>
        <el-form-item label="Categoria">
          <el-select class="w-full" v-model="formData.category" disabled>
            <el-option v-for="category of categories" :key="category.id" :value="category.id" :label="category.name">
              {{ category.name }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12" class="text-right">
        <a class="text-purple-400">
          <strong>&nbsp;</strong>
        </a>
        <el-form-item label="Subcategoria">
          <el-input v-model="formData.subcategory"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-form-item label="Monto">
          <el-input v-model="formData.amount" type="number" min="0" placeholder="Ingrese el monto"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="Pagado Hoy">
          <el-checkbox v-model="formData.isExpenseSettled" @change="formData.date = null"></el-checkbox>
        </el-form-item>
        <el-form-item label="Programar Fecha" v-if="!formData.isExpenseSettled">
          <el-date-picker type="date" placeholder="Programar Fecha" v-model="formData.date" style="width: 100%;"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-form-item>
          <el-checkbox slot="label" v-model="formData.addObservations" @change="formData.observations = '';">Observaciones</el-checkbox>
          <el-input v-if="formData.addObservations" type="textarea" v-model="formData.observations" :minRows="4"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item>
          <el-checkbox slot="label" v-model="formData.addReference" @change="formData.reference = '';">Referencia</el-checkbox>
          <el-input v-if="formData.addReference" v-model="formData.reference"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

  </el-form>
</template>
<script>
import { ExpenseCategories } from '@/constants';

export default {
  name: 'DynamicExpenseForm',
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      categories: ExpenseCategories.filter((category) => !category.isStaticCategory),
    }
  },
}
</script>