import HttpProxy from '@/services/HttpProxy';
import store from '@/store';
import { BUSINESS_READ, BUSINESS_OFFICES, BUSINESS_NOTIFICATIONS } from "@/store/modules/business/mutation-types";

export default class BusinessService {
  static async savePrintConfig(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'Business/PrintConfiguration', data,
    });
  
    if (!response?.data) {
      return null;
    }
    
    store.dispatch('invoices/update', { config: data.invoices });
    return response.data;
  }

  static async saveDateFormat(data) {
    await HttpProxy.submit({
      method: 'put',
      url: 'BusinessInfo/DateFormat',
      data,
    });

    store.dispatch('business/update', {
      ...store.state.business,
      dateFormat: data.dateFormat,
    });
  }

  static async getAll(parameters) {
    const response = await HttpProxy.submit({
      method: 'get',
      url: 'BusinessInfo/GetAll',
      parameters,
    });

    store.dispatch(BUSINESS_OFFICES, {
      ...store.state.business,
      offices: response.data,
    });
  }

  static async create(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'BusinessInfo', data,
    });
    if (!response?.data) {
      return null;
    }
    store.dispatch(BUSINESS_OFFICES, {
      ...store.state.business,
      offices: [...store.state.business.offices, {
        ...response.data,
        employees: [],
      }],
    });
  }

  static async update(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'BusinessInfo', data,
    });
    if (!response?.data) {
      return null;
    }
    const getNewOffice = (office) => ({
      address: response.data.address,
      businessId: response.data.businessId,
      country: response.data.country,
      createdAt: office.createdAt,
      currency: response.data.currency,
      dateFormat: response.data.dateFormat,
      deletedAt: null,
      employees: office.employees,
      id: response.data.id,
      isMainAccount: office.isMainAccount,
      logo: response.data.logo,
      name: response.data.name,
      phone: response.data.phone,
      phoneCountry: null,
      phoneCountryId: response.data.phoneCountryCodeId,
      policy: response.data.policy,
      timezone: response.data.timezone,
      updatedAt: response.data.updatedAt,
    });
    store.dispatch(BUSINESS_OFFICES, {
      ...store.state.business,
      offices: store.state.business.offices
        .map((office) => office.id === response.data.id
          ? getNewOffice(office): office),
    });

    const newOffice = getNewOffice(store.state.business);
    if (store.state.business.id === newOffice.id) {
      store.dispatch(BUSINESS_READ, getNewOffice(store.state.business));
    }
  }

  static async assign(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'AccountBusinessInfo/assign', data,
    });
    return response?.data ?? null;
  }

  static async unassign(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'AccountBusinessInfo/unassign', data,
    });
    return response?.data ?? null;
  }

  static async notifications(parameters) {
    const response = await HttpProxy.submit({
      method: 'get', url: 'Business/Notification', parameters,
    });

    store.dispatch(BUSINESS_NOTIFICATIONS, {
      notifications: response.data,
    });
  }

  static async saveNotifications(data) {
    await HttpProxy.submit({
      method: 'post', url: 'Business/Notification', data,
    });
    await this.notifications();
  }

  static async closure(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'Business/Closure', data,
    });

    return response?.data ?? null;
  }

  static async webhook(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'business/webhook', data,
    });
    return response?.data ?? null;
  }
}
