import HttpProxy from '@/services/HttpProxy';
import store from '@/store';
import {
  BUSINESS_READ,
  BUSINESS_UPDATE,
  BUSINESS_EMPLOYEES
} from '@/store/modules/business/mutation-types';
import {
  ACCOUNT_REPLACE_CURRENCY,
} from '@/store/modules/account/mutation-types';

export default class AdminService {
  static async syncEmployees() {
    const response = await HttpProxy.submit({ method: 'get', url: 'Account/ByBusiness' });
    if (!response?.data) {
      return null;
    }
    store.dispatch(BUSINESS_EMPLOYEES, { employees: response.data });
  }

  static MyBusiness = {
    read: async () => {
      const response = await HttpProxy.submit({
        method: 'get', url: 'BusinessInfo',
      });
      if (!response?.data) {
        return null;
      }
      store.dispatch(BUSINESS_READ, response.data);
    },
    update: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'BusinessInfo', data,
      });
      if (!response.data) {
        return null;
      }
      store.dispatch(BUSINESS_UPDATE, response.data);
      store.dispatch(ACCOUNT_REPLACE_CURRENCY, { currencySymbol: response.data.currencySymbol ?? response.data.currency });
    },
  };

  static MyMembership = {
    createPromotionCode: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Account/AddCoupon', data,
      });
      return response?.data ?? null;
    },
  };

  static UsersAndPermissions = {
    sendInvite: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Sign/send-invitation', data,
      });
      return response?.data ?? null;
    },
    getUsers: async (parameters) => {
      const response = await HttpProxy.submit({
        method: 'get', url: 'Account/ByBusiness', parameters,
      });
      return response?.data ?? null;
    },
    updateAccountType: async (userId, data) => {
      const response = await HttpProxy.submit({
        method: 'put', url: `Account/AccountType/${userId}`, data,
      });
      return response?.data ?? null;
    },
    updatePermit: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Account/UpdatePermission', data,
      });
      return response?.data ?? null;
    },
    getOrders: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: `Account/AccountOrders`, data,
      });
      return response?.data ?? null;
    },
    updateCommission: async (data) => {
      const response = await HttpProxy.submit({
        method: 'put', url: `Account/UpdateCommission`, data,
      });
      return response?.data ?? null;
    },
    deleteAccount: async (accountId) => {
      const response = await HttpProxy.submit({
        method: 'delete', url: `Account/DeleteAccount/${accountId}`, parameters: {},
      });
      return response?.data ?? null;
    },
  }

  static PoliciesAndTemplates = {
    update: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'BusinessInfo/Policy', data,
      });
      return response?.data ?? null;
    },
    read: async () => {
      const response = await HttpProxy.submit({
        method: 'get', url: 'BusinessInfo/Policy',
      });
      return response?.data ?? null;
    },
  };

  static ImportExport = {
    Client: {
      get: async (businessInfoId) => {
        const response = await HttpProxy.submit({
          method: 'get', url: `Clients/DownloadFile?businessInfoId=${businessInfoId}`, parameters: {},
        });
        return response?.data ?? null;
      },
      update: async (data, businessInfoId) => {
        const response = await HttpProxy.submit({
          method: 'post', url: `Clients/UploadFromFile?businessInfoId=${businessInfoId}`, data, headers: {'Content-Type': 'multipart/form-data'}
        });
        return response.status;
      },
    },
    Product: {
      get: async (businessInfoId) => {
        const response = await HttpProxy.submit({
          method: 'get', url: `Products/DownloadFile?businessInfoId=${businessInfoId}`, parameters: {},
        });
        return response?.data ?? null;
      },
      update: async (data, businessInfoId) => {
        const response = await HttpProxy.submit({
          method: 'post', url: `Products/UploadFromFile?businessInfoId=${businessInfoId}`, data, headers: {'Content-Type': 'multipart/form-data'}
        });
        return response.status;
      },
    },
  }

  static MyData = {
    update: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Account/UpdateAccount', data,
      });
      return response?.data ?? null;
    },
  }

  static Security = {
    update: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Account/UpdatePassword', data,
      });
      return response?.data ?? null;
    },
  }

  static GlabalSearch = {
    search: async (query) => {
      const response = await HttpProxy.submit({
        method: 'get', url: `Search?textSearch=${query}`,
      });
      return response?.data ?? null;
    },
  }
}
