import * as types from "./mutation-types";

export const read = ({ commit }, data) => {
  commit(types.BUSINESS_READ, data);
};

export const update = ({ commit }, data) => {
  commit(types.BUSINESS_UPDATE, data);
};

export const employees = ({ commit }, data) => {
  commit(types.BUSINESS_EMPLOYEES, data);
};

export const offices = ({ commit }, data) => {
  commit(types.BUSINESS_OFFICES, data);
};

export const notifications = ({ commit }, data) => {
  commit(types.BUSINESS_NOTIFICATIONS, data);
};

export const destroy = ({ commit }) => {
  commit(types.BUSINESS_DESTROY);
};

export default {
  read,
  update,
  employees,
  offices,
  notifications,
  destroy,
};
