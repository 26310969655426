<template>
  <el-dialog :visible.sync="visible" :width="width" @close="close" v-loading.fullscreen.lock="loading">
    <div class="w-full flex flex-row mb-2">
      <h1 class="text-lg font-bold inline-flex items-center">
        <strong>&nbsp;Gasto {{ { 1: 'Fijo', 2: 'Variable', 3: 'Inventario' }[expenseType] }}</strong>
      </h1>
    </div>

    <div v-if="!expenseType" class="w-full flex flex-row justify-between space-x-2">
      <div class="w-full mx-auto max-w-xs">
        <div
          class="bg-white rounded-xl shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg cursor-pointer"
          @click="setExpenseType(ExpenseType.Static)">
          <div class="flex items-center justify-center h-20 bg-purple-500">
            <i class='text-5xl bx bx-receipt text-white'></i>
          </div>
          <div class="px-6 py-4">
            <div class="font-bold text-xl text-center mb-2 text-purple-500">Fijo</div>
          </div>
        </div>
      </div>
      <div class="w-full mx-auto max-w-xs">
        <div
          class="bg-white rounded-xl shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg cursor-pointer"
          @click="setExpenseType(ExpenseType.Dynamic)">
          <div class="flex items-center justify-center h-20 bg-yellow-500">
            <i class='text-5xl bx bx-money text-white'></i>
          </div>
          <div class="px-6 py-4">
            <div class="font-bold text-xl text-center mb-2 text-yellow-500">Variable</div>
          </div>
        </div>
      </div>
      <div class="w-full mx-auto max-w-xs">
        <div
          class="bg-white rounded-xl shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg cursor-pointer"
          @click="setExpenseType(ExpenseType.Product)">
          <div class="flex items-center justify-center h-20 bg-green-500">
            <i class='text-5xl bx bx-package text-white'></i>
          </div>
          <div class="px-6 py-4">
            <div class="font-bold text-xl text-center mb-2 text-green-500">Inventario</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="expenseType === ExpenseType.Static">
        <static-expense-form :formData="formData"></static-expense-form>
      </div>

      <div v-if="expenseType === ExpenseType.Dynamic">
        <dynamic-expense-form :formData="formData"></dynamic-expense-form>
      </div>

      <div v-if="expenseType === ExpenseType.Product">
        <inventory-expense-form-list :formData="formData" :formList="formList" :active="active"
          @on:active:update="active = $event"></inventory-expense-form-list>
      </div>

      <div class="w-full text-right mt-4">
        <el-button v-if="expenseType === ExpenseType.Product" type="success" @click="quote">
          Whatsapp
        </el-button>
        <el-button v-if="expenseType === ExpenseType.Product" type="warning" @click="showProductDialog = true;">
          Crear Nuevo Producto
        </el-button>
        <el-button type="primary" @click="save" :disabled="!isFormValid">
          Crear Gasto
        </el-button>
      </div>
    </div>

    <el-dialog append-to-body show-close :visible.sync="showProductDialog" :width="width">
      <div class="w-full flex justify-between">
        <span class="font-bold text-md">Crear Producto</span>
        <el-button class="el-button--primary" @click="createProduct">Crear Gasto</el-button>
      </div>
      <product-form v-if="showProductDialog" :loading="creatingProduct" :form="product" hide-quantity-controls />
    </el-dialog>

    <el-dialog append-to-body :visible.sync="showPaymentDialog" :width="width">
      <payment-expense-form @save:payment="handlePayment"></payment-expense-form>
    </el-dialog>
  </el-dialog>
</template>
<script>
import service from "./service";
import error from "@/mixins/error";
import { ExpenseType, ExpenseCategory } from "@/constants";
import ExpenseService from "@/services/ExpenseService";
import ProductService from '@/services/ProductService';
import { EXPENSES_STORE } from "@/store/modules/expenses/mutation-types";
import StaticExpenseForm from "./components/StaticExpenseForm";
import DynamicExpenseForm from "./components/DynamicExpenseForm";
import InventoryExpenseFormList from "./components/InventoryExpenseFormList";
import PaymentExpenseForm from "./components/PaymentExpenseForm";
import ProductForm from "@/views/app/products/Form";
import { Whatsapp, getCountryCode } from '@/utils/phones';
import {
  getPostRequest,
  getPutRequest,
  isStaticExpenseFormValid,
  isDynamicExpenseFormValid,
  isProductExpenseFormValid,
} from "./utils";
import SupplierService from "@/services/SupplierService";
import { tag } from '@/constants';

export default {
  name: 'Expense',
  mixins: [error],
  components: {
    StaticExpenseForm,
    DynamicExpenseForm,
    InventoryExpenseFormList,
    PaymentExpenseForm,
    ProductForm,
  },
  data() {
    return {
      subscription: null,
      visible: false,
      width: '60%',
      loading: false,
      expense: null,
      expenseType: null,
      formData: {
        category: null,
        subcategory: null,
        observations: '',
        reference: '',
        amount: 0,
        date: null,
        products: [],
        addObservations: false,
        addReference: false,
        isExpenseSettled: true,
      },
      formList: [],
      expenses: [
        { id: 1, name: "Gasto Variable #1" },
        { id: 2, name: "Gasto Variable #2" },
        { id: 3, name: "Gasto Variable #3" },
      ],
      ExpenseType,
      ExpenseCategory,
      showPaymentDialog: false,
      showProductDialog: false,
      creatingProduct: false,
      product: {
        name: '',
        description: '',
        category: null,
        brand_model: '',
        cost: 0,
        price: 0,
        quantity: 0,
        minStockAlert: null,
        maxStockAlert: null,
      },
      active: 0,
    }
  },
  mounted() {
    this.subscription = service.subscribe(this.open);
  },
  destroyed() {
    this.subscription.unsubscribe();
  },
  computed: {
    isFormValid() {
      switch (this.expenseType) {
        case ExpenseType.Static:
          return isStaticExpenseFormValid(this.formData);
        case ExpenseType.Dynamic:
          return isDynamicExpenseFormValid(this.formData);
        case ExpenseType.Product:
          return isProductExpenseFormValid(this.formData, this.formList);
        default:
          return true;
      }
    },
  },
  methods: {
    open(payload = null) {
      this.visible = true;
      if (payload) {
        this.expenseType = payload.expenseType;
        this.formData = {
          id: payload.id,
          category: payload.expenseCategory,
          subcategory: payload.expenseSubcategory,
          observations: payload.observations,
          reference: payload.reference,
          amount: payload.amount,
          date: payload.expenseDate,
          products: [],
          addObservations: !!payload.observations,
          addReference: !!payload.reference,
        };
        this.formList = payload.products.map((product) => ({
          category: product.category,
          cost: product.cost,
          addObservations: !!product.observations,
          observations: product.observations,
          addReference: !!product.reference,
          reference: product.reference,
          quantity: product.quantity,
          subcategory: {
            id: product.id,
            name: product.name,
          },
          supplier: product.supplierId,
        }));
      }
    },
    close() {
      this.visible = false;
      this.formData = {
        id: null,
        category: null,
        subcategory: null,
        observations: '',
        reference: '',
        amount: 0,
        date: null,
        products: [],
        addObservations: false,
        addReference: false,
        isExpenseSettled: true,
      };
      this.formList = [];
      this.expenseType = null;
    },
    setExpenseType(expenseType) {
      this.expenseType = expenseType;
      if (this.expenseType == ExpenseType.Dynamic) {
        this.formData.category = ExpenseCategory.Other;
      } else if (this.expenseType == ExpenseType.Product) {
        this.formData.category = ExpenseCategory.Product;
      }
    },
    getExpenseDate(isExpenseSettled, date) {
      if (isExpenseSettled) {
        return new Date();
      }
      return date;
    },
    async create(paymentMethod) {
      this.loading = true;
      try {
        const data = getPostRequest(this.expenseType, this.formData, this.formList, paymentMethod);
        const { id } = await ExpenseService.create({ ...data, businessInfoId: this.$store.state.business.id });
        this.$store.dispatch(EXPENSES_STORE, {
          ...this.$store.state.expenses,
          entities: [...this.$store.state.expenses.entities, {
            id,
            expenseType: this.expenseType,
            expenseCategory: this.formData.category,
            expenseSubcategory: this.formData.subcategory,
            observations: this.formData.observations,
            reference: this.formData.reference,
            amount: this.formData.amount,
            expenseDate: this.getExpenseDate(this.formData.isExpenseSettled, this.formData.date),
            createdAt: new Date(),
            products: data.products,
            paymentMethod,
          }],
        });
        this.$toastr.s("Se creó con exito");
        this.close();
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    async update(paymentMethod) {
      this.loading = true;
      try {
        const data = getPutRequest(this.expenseType, this.formData, this.formList, paymentMethod);
        await ExpenseService.update(data);
        this.$store.dispatch(EXPENSES_STORE, {
          ...this.$store.state.expenses,
          entities: this.$store.state.expenses.entities.map((entity) => entity.id === data.id
            ? ({
              id: entity.id,
              expenseType: this.expenseType,
              expenseCategory: this.formData.category,
              expenseSubcategory: this.formData.subcategory,
              observations: this.formData.observations,
              reference: this.formData.reference,
              amount: this.formData.amount,
              expenseDate: this.getExpenseDate(this.formData.isExpenseSettled, this.formData.date),
              createdAt: entity.createdAt,
              products: data.products,
              paymentMethod,
            }) : entity),
        });
        this.$toastr.s("Se actualizó con exito");
        this.close();
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    save() {
      if (!this.isFormValid) {
        this.$toastr.e("Algo salió mal.");
        return;
      }
      this.showPaymentDialog = true;
    },
    handlePayment(paymentMethod) {
      this.showPaymentDialog = false;

      if (this.formData.id) {
        this.update(paymentMethod);
      } else {
        this.create(paymentMethod);
      }
    },
    async createProduct() {
      if (this.creatingProduct) return;
      this.creatingProduct = true;
      try {
        const request = {
          category: this.product.category,
          name: this.product.name,
          description: this.product.description,
          cost: parseFloat(this.product.cost),
          price: parseFloat(this.product.price),
          quantity: 0,
          minStockAlert: null,
          maxStockAlert: null,
        };
        const { id } = await ProductService.create({ product: request });
        await this.$toastr.s('Se creo con exito');
        this.formList = this.formList.map((form, index) => index === this.active ? ({
          ...form,
          category: this.product.category,
          subcategory: {
            id,
            name: this.product.name,
          },
          cost: parseFloat(this.product.cost),
          quantity: 1,
          hasError: false,
        }) : form);
        this.formData.amount += parseFloat(this.product.cost);
        this.showProductDialog = false;
        this.product = {
          name: '',
          description: '',
          category: null,
          brand_model: '',
          cost: 0,
          price: 0,
          quantity: 0,
          minStockAlert: null,
          maxStockAlert: null,
        };
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toatr.e(errorMessage);
      } finally {
        this.creatingProduct = false;
      }
    },
    quote() {
      if (!this.isFormValid) {
        this.$toastr.e("Falta informacion en el formulario");
        return;
      }
      const supplierId = this.formList[this.active].supplierId;
      if (!supplierId) {
        this.$toastr.e("El producto no cuenta con proveedor");
        return;
      }
      this.sendExpenseForQuote(supplierId);
    },
    async sendExpenseForQuote(supplierId) {
      this.loading = true;
      try {
        const supplier = await SupplierService.getbyId(supplierId);
        const countryCode = getCountryCode(
          this.$store.state.catalogs.flags,
          supplier.phoneCountryCodeId,
        );
        if (!countryCode) {
          this.$toastr.e('El Proveedor debe tener registrado el Código de Area');
          return;
        }
        const activeFormList = this.formList;
        const data = getPostRequest(this.expenseType, this.formData, activeFormList, null);
        const pdf = await ExpenseService.quote(data);
        Whatsapp({
          code: countryCode,
          phone: supplier.phone,
          text: `${supplier.company}; Orden de compra. ${tag.br}${tag.br}${pdf}`,
        });
      } catch (e) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
