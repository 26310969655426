import checkAuthAccount from '@/router/middlewares/checkAuthAccount';

export default [
  {
    path: '/welcome/:step?',
    name: 'onboarding.index',
    component: () => import('@/views/app/onboarding/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/home',
    name: 'home.index',
    component: () => import('@/views/app/HomeIndex.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/close-day',
    name: 'close.day',
    component: () => import('@/views/app/close-day/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/close-day/:businessInfoId',
    name: 'close.business',
    component: () => import('@/views/app/close-day/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/orders',
    name: 'orders.index',
    component: () => import('@/views/app/orders/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/orders/filter/:clientid/:client',
    name: 'orders.index-filter',
    component: () => import('@/views/app/orders/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/orders/edit/:id',
    name: 'orders.read',
    component: () => import('@/views/app/orders/form/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ],
    },
  },
  {
    path: '/orders/create',
    name: 'orders.create',
    component: () => import('@/views/app/orders/form/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/orders/client/:id/:fullName',
    name: 'orders.client',
    component: () => import('@/views/app/orders/client/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/invoices/default/:id',
    name: 'invoices.default',
    component: () => import('@/views/app/invoices/Default.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/invoices/thermal/:id',
    name: 'invoices.thermal',
    component: () => import('@/views/app/invoices/Thermal.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/invoices/sticker/:id',
    name: 'invoices.sticker',
    component: () => import('@/views/app/invoices/Sticker.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/clients',
    name: 'clients.index',
    component: () => import('@/views/app/clients/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/clients/:id',
    name: 'clients.edit',
    component: () => import('@/views/app/clients/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/clients/create',
    name: 'clients.create',
    component: () => import('@/views/app/clients/CreateClient.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/suppliers',
    name: 'suppliers.index',
    component: () => import('@/views/app/suppliers/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/suppliers/create',
    name: 'suppliers.create',
    component: () => import('@/views/app/suppliers/CreateSupplier.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/services',
    name: 'services.index',
    component: () => import('@/views/app/services/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/products',
    name: 'products.index',
    component: () => import('@/views/app/products/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/products/edit/:id',
    name: 'products.read',
    component: () => import('@/views/app/products/Update.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    },
  },
  {
    path: '/products/create',
    name: 'products.create',
    component: () => import('@/views/app/products/Create.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    },
  },
  {
    path: '/finances',
    name: 'finances.index',
    component: () => import('@/views/app/finances/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ]
    }
  },
  {
    path: '/courses',
    name: 'courses.index',
    component: () => import('@/views/app/courses/Index.vue'),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/expenses',
    name: 'expenses.index',
    component: () => import("@/views/app/expenses/Index.vue"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  // ADMIN
  {
    path: '/administration/business',
    name: 'administration.business',
    component: () => import("@/views/app/administration/business"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/membership',
    name: 'administration.membership',
    component: () => import("@/views/app/administration/membership"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/users',
    name: 'administration.users',
    component: () => import("@/views/app/administration/users"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/offices',
    name: 'administration.offices',
    component: () => import("@/views/app/administration/offices"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/notifications',
    name: 'administration.notifications',
    component: () => import("@/views/app/administration/notifications"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/order-preferences',
    name: 'administration.orderPreferences',
    component: () => import("@/views/app/administration/order-preferences"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/import-export',
    name: 'administration.importExport',
    component: () => import("@/views/app/administration/import-export"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/catalogs',
    name: 'administration.catalogs',
    component: () => import("@/views/app/administration/catalogs"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/account',
    name: 'administration.account',
    component: () => import("@/views/app/administration/account"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
  {
    path: '/administration/security',
    name: 'administration.security',
    component: () => import("@/views/app/administration/security"),
    meta: {
      middlewares: [ checkAuthAccount ],
    }
  },
];
