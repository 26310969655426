import HttpProxy from '@/services/HttpProxy';
import store from '@/store';
import {
  NOTIFICATIONS_STORE,
  NOTIFICATIONS_DELETE
} from '@/store/modules/notifications/mutation-types';

export default class NotificationService {
  static async get(parameters = {}) {
    const response = await HttpProxy.submit({
      method: 'get', url: 'Notifications', parameters,
    });
    store.dispatch(NOTIFICATIONS_STORE, response?.data ?? []);
  }

  static async markAsRead(id) {
    const response = await HttpProxy.submit({
      method: 'put', url: `Notifications/${id}`, data: { notificationId: id },
    });
    return response?.data ?? null;
  }

  static async delete(ids) {
    await HttpProxy.submit({
      method: 'delete', url: 'Notifications', data: { notificationIds: ids },
    });
    store.dispatch(NOTIFICATIONS_DELETE, ids);
  }
}
