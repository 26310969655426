import { render, staticRenderFns } from "./SaReview.vue?vue&type=template&id=9abce372&scoped=true&"
import script from "./SaReview.vue?vue&type=script&lang=js&"
export * from "./SaReview.vue?vue&type=script&lang=js&"
import style0 from "./SaReview.vue?vue&type=style&index=0&id=9abce372&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9abce372",
  null
  
)

export default component.exports