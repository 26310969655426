import _ from "lodash";
import { ExpenseType } from '@/constants';

const getExpensePostRequest = (expenseType, formData, formList, paymentMethod) => {
  const {
    category,
    subcategory,
    observations,
    reference,
    amount,
    date,
    isExpenseSettled,
  } = formData;

  return {
    expenseType: expenseType,
    expenseCategory: category,
    expenseSubcategory: subcategory,
    observations,
    reference,
    amount,
    expenseDate: isExpenseSettled
      ? new Date().toISOString()
      : new Date(date).toISOString(),
    products: [],
    paymentMethodId: paymentMethod,
    isExpenseSettled,
  };
};

const getExpenseProductPostRequest = (expenseType, formData, formList, paymentMethod) => {
  const { category, date, isExpenseSettled } = formData;

  let amount = 0;
  const products = formList.map((form) => {
    const { category, cost, observations, reference, quantity, subcategory, supplierId } = form;

    amount += (parseFloat(cost) * parseFloat(quantity));

    return {
      ...subcategory,
      category,
      observations,
      reference,
      cost,
      quantity,
      supplierId,
    };
  });

  return {
    expenseType: expenseType,
    expenseCategory: category,
    expenseSubcategory: null,
    observations: '',
    reference: '',
    amount,
    expenseDate: isExpenseSettled
      ? new Date().toISOString()
      : new Date(date).toISOString(),
    products,
    paymentMethodId: paymentMethod,
    isExpenseSettled,
  };
}

const ExpensePostRequestLookup = {
  [ExpenseType.Static]: getExpensePostRequest,
  [ExpenseType.Dynamic]: getExpensePostRequest,
  [ExpenseType.Product]: getExpenseProductPostRequest,
};

const getPostRequest = (expenseType, formData, formList, paymentMethod) => {
  const builder = ExpensePostRequestLookup[expenseType];
  return builder(expenseType, formData, formList, paymentMethod);
};

const getPutRequest = (expenseType, formData, formList, paymentMethod) => {
  const postRequest = getPostRequest(expenseType, formData, formList, paymentMethod);

  return {
    ...postRequest,
    id: formData.id,
  };
};

const getIsValidExpenseCategory = (expenseCategory = null) => !_.isNil(expenseCategory);

const getIsValidExpenseSubcategory = (expenseSubcategory = null) => !_.isNil(expenseSubcategory);

const getIsValidAmount = (amount = 0) => parseFloat(amount) > 0;

const getIsValidExpenseDate = (expenseDate = null) => !_.isNil(expenseDate);

const getIsValidQuantity = (quantity = 0) => parseFloat(quantity) > 0;

const getIsValidCost = (cost = 0) => parseFloat(cost) > 0;

const isStaticExpenseFormValid = ({ category, subcategory, amount, date, isExpenseSettled }) => {
  const isValidCategory = getIsValidExpenseCategory(category);
  const isValidSubcategory = getIsValidExpenseSubcategory(subcategory);
  const isValidAmount = getIsValidAmount(amount);
  const isValidDate = getIsValidExpenseDate(date);

  return isValidCategory && isValidSubcategory && isValidAmount && (isValidDate || isExpenseSettled);
};

const isDynamicExpenseFormValid = ({ amount, date, isExpenseSettled }) => {
  const isValidAmount = getIsValidAmount(amount);
  const isValidDate = getIsValidExpenseDate(date);

  return isValidAmount && (isValidDate || isExpenseSettled);
};

const isProductExpenseFormValid = (formData, formList = []) => {
  const isValidDate = getIsValidExpenseDate(formData.date);

  const isValidFormData = (isValidDate || formData.isExpenseSettled) && getIsValidAmount(formData.amount);

  const isValidFormList = formList.every(isProductFormValid);

  return isValidFormData && isValidFormList;
};

const isProductFormValid = (form) => (
  getIsValidExpenseCategory(form.category)
  && getIsValidExpenseSubcategory(form.subcategory)
  && getIsValidQuantity(form.quantity)
  && getIsValidCost(form.cost)
);

export {
  getPostRequest,
  getPutRequest,
  isStaticExpenseFormValid,
  isDynamicExpenseFormValid,
  isProductExpenseFormValid,
  isProductFormValid,
};
