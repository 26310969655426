<template>
  <div v-if="active === index">
    <el-form :model="formData" label-position="right" label-width="120px">

      <el-row>
        <el-col :span="12" class="text-right">
          <a class="text-purple-400">
            <strong>&nbsp;</strong>
          </a>
          <el-form-item label="Categoria">
            <el-select class="w-full" v-model="formData.category" @change="handleCategoryChange">
              <el-option v-for="productCategory of $store.state.catalogs.product.categories" :key="productCategory.id"
                :value="productCategory.id" :label="productCategory.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="text-right">
          <a class="text-purple-400">
            <strong>&nbsp;</strong>
          </a>
          <el-form-item label="Subcategoria">
            <el-autocomplete v-model="formData.subcategory.name" :trigger-on-focus="false" :fetch-suggestions="fetchSuggestions"
                :disabled="!formData.category" class="w-full" popper-class="product-autocomplete"
                @select="handleSelect">
                <template slot-scope="{ item }">
                  <div class="name">{{ item.name }}</div>
                  <span class="category">{{ item.category }}</span>
                </template>
              </el-autocomplete>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12" class="text-right">
          <a class="text-purple-400">
            <strong>&nbsp;</strong>
          </a>
          <el-form-item label="Proveedor">
            <el-select class="w-full" v-model="supplier" disabled placeholder="">
              <el-option v-for="s of suppliers" :key="s.id"
                :value="s.id" :label="s.company">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="Costo" style="position: relative;">
            <el-input v-if="disabledAmount" v-model="formData.cost" disabled>
              <template v-slot:append>
                <button
                  title="Editar"
                  class="el-button--primary rounded-full cursor-pointer"
                  @click="handleEditAmount">
                  <i class="bx bx-edit" style="padding: 4px; font-size: 20px;"></i>
                </button>
              </template>
            </el-input>

            <el-input v-if="!disabledAmount" type="number" min="0" v-model="formData.cost" @change="updateProduct('cost', $event)">
              <template v-slot:append>
                <button
                  title="Eliminar"
                  class="el-button--danger rounded-full cursor-pointer"
                  @click="handleEditAmount">
                  <i class="bx bx-x" style="padding: 4px; font-size: 20px;"></i>
                </button>
              </template>
            </el-input>

            <span
              v-if="!disabledAmount"
              class="helper-text"
              style="color: red; font-size: x-small; position: absolute; display: flex; top: 24px;">
              El costo se actualizará en el inventario (costo / cantidad)
            </span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Cantidad">
            <el-input type="number" min="0" v-model="formData.quantity" @change="updateProduct('quantity', $event)"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item>
            <el-checkbox slot="label" v-model="formData.addObservations"
              @change="formData.observations = '';">Observaciones</el-checkbox>
            <el-input v-if="formData.addObservations" type="textarea" v-model="formData.observations" :minRows="4"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item>
            <el-checkbox slot="label" v-model="formData.addReference" @change="formData.reference = '';">Referencia</el-checkbox>
            <el-input v-if="formData.addReference" v-model="formData.reference"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
  </div>
</template>
<style>
.product-autocomplete {
  li {
    line-height: normal !important;
    padding: 7px !important;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .category {
      font-size: 12px;
      color: #b4b4b4;
    }
  }
}
</style>
<script>
import ProductService from "@/services/ProductService";
import SupplierService from "@/services/SupplierService";
import error from "@/mixins/error";

export default {
  name: 'InventoryExpenseForm',
  mixins: [error],
  props: {
    formData: {
      type: Object,
      default: () => { },
    },
    index: {
      type: Number,
      default: () => 0,
    },
    active: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      disabledAmount: true,
      supplier: null,
      suppliers: [],
    }
  },
  mounted() {
    if (this.suppliers.length === 0) this.loadSuppliers();
    this.supplier = this.formData.supplier || null;
  },
  methods: {
    async fetchSuggestions(queryString, callback) {
      try {
        const products = await ProductService.getAll({
          businessInfoId: this.$store.state.business.id,
          textSearch: queryString,
          category: this.formData.category,
        });
        callback(products);
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);
        this.$toastr.e(errorMessage);
      }
    },
    handleSelect(item) {
      this.formData.subcategory.id = item.id;
      this.formData.subcategory.name = item.name;
      this.formData.cost = item.cost;
      this.formData.quantity = 1;
      this.updateProduct('onSelect');
      this.supplier = item.supplierId;
      this.formData.supplierId = item.supplierId;
    },
    handleEditAmount(event) {
      event.preventDefault();
      this.disabledAmount = !this.disabledAmount;
    },
    updateProduct(type, value) {
      const product = { ...this.formData };

      if (type === 'onSelect' || type === 'clear') {
        this.$emit('on:product:change');
        return;
      }

      product[type] = value;
      this.$emit('on:product:change');
    },
    handleCategoryChange() {
      this.formData.subcategory = {
        id: null,
        name: '',
      };
      this.formData.cost = 0;
      this.formData.quantity = 0;
      this.updateProduct('clear')
    },
    async loadSuppliers() {
      try {
        this.suppliers = await SupplierService.getAll({
          businessInfoId: this.$store.state.business.id,
        });
      } catch (e) {
        this.errorMessage = e;
      }
    },
  }
}
</script>