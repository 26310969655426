<template>
  <el-form :model="formData" label-position="right" label-width="120px">

    <el-row>
      <el-col :span="12" class="text-right">
        <a class="text-purple-400">
          <strong>&nbsp;</strong>
        </a>
        <el-form-item label="Categoria">
          <el-select class="w-full" v-model="formData.category" @change="formData.subcategory = null;">
            <el-option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
              :label="category.name">
              {{ category.name }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12" class="text-right">
        <a class="text-purple-400" @click="showCreateCategoryDialog">
          <strong>+ Agregar Nueva</strong>
        </a>
        <el-form-item label="Subcategoria">
          <el-select class="w-full" v-model="formData.subcategory" :disabled="!formData.category">
            <el-option
              v-for="subcategory in filteredSubcategories"
              :key="subcategory.id"
              :value="subcategory.id"
              :label="subcategory.name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-form-item label="Monto">
          <el-input v-model="formData.amount" type="number" min="0" placeholder="Ingrese el monto"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="Pagado Hoy">
          <el-checkbox v-model="formData.isExpenseSettled" @change="formData.date = null"></el-checkbox>
        </el-form-item>
        <el-form-item label="Programar Fecha" v-if="!formData.isExpenseSettled">
          <el-date-picker type="date" placeholder="Programar Fecha" v-model="formData.date" style="width: 100%;"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    
    <el-row>
      <el-col :span="12">
        <el-form-item>
          <el-checkbox slot="label" v-model="formData.addObservations" @change="formData.observations = '';">Observaciones</el-checkbox>
          <el-input v-if="formData.addObservations" type="textarea" v-model="formData.observations" :minRows="4"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item>
          <el-checkbox slot="label" v-model="formData.addReference" @change="formData.reference = '';">Referencia</el-checkbox>
          <el-input v-if="formData.addReference" v-model="formData.reference"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

  </el-form>
</template>
<script>
import _ from "lodash";
import { ExpenseCategories } from "@/constants";
import CategoryDialogService, { command as CategoryDialogCommand, commands } from '@/components/dialogs/Category/service';
import CategoryEnum from '@/components/dialogs/Category/enum';
import { EXPENSES_STORE } from "@/store/modules/expenses/mutation-types";

export default {
  name: 'StaticExpenseForm',
  props: {
    formData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      subscription: null,
      categories: ExpenseCategories.filter((category) => category.isStaticCategory),
    }
  },
  computed: {
    filteredSubcategories() {
      if (_.isNil(this.formData.category)) {
        return [];
      }
      return this.$store.state.expenses.subcategories
        .filter((subcategory) => subcategory.category === this.formData.category);
    }
  },
  methods: {
    showCreateCategoryDialog() {
      CategoryDialogService.show({
        type: CategoryEnum.Expense,
        title: 'Agregar nueva subcategoria de Gastos Fijos',
        customProps: { category: this.formData.category },
      });
    },
    handleCommands({ type, payload }) {
      switch (type) {
        case commands.create:
          this.createCategory(payload);
          break;
        default:
      }
    },
    async createCategory({ id, category, name }) {
      this.$store.dispatch(EXPENSES_STORE, {
        ...this.$store.state.expenses,
        subcategories: [...this.$store.state.expenses.subcategories, {
          id, category, name
        }],
      });
      this.formData.subcategory = id;
    },
  },
  created() {
    this.subscription = CategoryDialogCommand.subscribe(this.handleCommands);
  },
  destroyed() {
    this.subsciption?.unsubscribe();
  }
}
</script>