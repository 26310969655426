<template>
  <el-dialog :visible.sync="visible" :width="width" @close="close">
      <div>
        <div class="mb-4">
          <span class="text-md font-bold break-words">
            Por favor, selecciona un metodo de pago para finalizar la orden
          </span>
        </div>
        <span>Metodo de Pago</span>
        <el-select class="w-full" v-model="value" @change="onSelect">
          <el-option
            v-for="paymentMethod in $store.state.catalogs.paymentMethods"
            :key="paymentMethod.id"
            :label="paymentMethod.name"
            :value="paymentMethod.id">
            <div class="flex flex-row items-center">
              <span class="ml-2">
                {{ paymentMethod.name }}
              </span>
            </div>
          </el-option>
        </el-select>
        <br>
        <span>Fecha cierre</span>
        <el-date-picker
          class="w-full datepicker-input-align-center"
          v-model="dateEnd"
          type="date"
          placeholder="Pick a day"
          @change="onChangeEndDate"
        >
        </el-date-picker>
      </div>
    <div class="w-full text-right mt-2">
    <el-button size="small" type="primary" @click="savePaymentMethod">Aceptar</el-button>
    </div>
    </el-dialog>
</template>
<style scoped>
.datepicker-input-align-center /deep/ .el-input__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
import _ from 'lodash';
import _service, { command as _command, commands } from './service';
import error from '@/mixins/error';
import responsiveSize from '@/mixins/responsiveSize';

export default {
  name: 'OrderPayment',
  mixins: [responsiveSize, error],
  data() {
    return {
      visible: false,
      value: null,
      width: '40%',
      paymentMethods: [],
      payload: {},
      dateEnd: new Date(),
      paymentMethodValue: null,
    }
  },
  created() {
    this.subscription = _service.subscribe(this.open);
    window.addEventListener('resize', _.debounce(this.resize, 300));
    this.resize();
  },
  destroyed() {
    this.subscription.unsubscribe();
    window.removeEventListener('resize', _.debounce(this.resize, 300));
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.width = percentageWidth === '30%' ? '40%' : percentageWidth;
    },
    open(payload) {
      this.visible = true;
      this.payload = payload;
    },
    close() {
      this.visible = false;
      this.value = null;
      this.paymentMethods = [];
      this.dateEnd = new Date();
    },
    onSelect(paymentMethod) {
      this.paymentMethodValue = paymentMethod;
    },
    onChangeEndDate(newDate) {
      this.dateEnd = newDate;
    },
    savePaymentMethod() {
      const endDate = new Date(this.dateEnd).toISOString();
      _command.onCommand(commands.Select, { ...this.payload, paymentMethod: this.paymentMethodValue, endDate });
      this.close();
    }
  }
}
</script>

