<template>
    <div>
      <canvas ref="chartCanvas"></canvas>
    </div>
  </template>
  
  <script>
  import Chart from "chart.js";
  import numbers from '@/utils/numbers';
  
  export default {
    props: {
      labels: {
        type: Array,
        required: true,
      },
      datasets: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        chartInstance: null,
      };
    },
    watch: {
      labels: "updateChart",
      datasets: "updateChart",
    },
    methods: {
      moneyFormat(value) {
        const { currencySymbol } = this.$store.state.account;
        return numbers.moneyFormat(value, currencySymbol);
      },
      renderChart() {
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }
  
        const ctx = this.$refs.chartCanvas.getContext("2d");
        const self = this;

        this.chartInstance = new Chart(ctx, {
          type: "bar",
          data: {
            labels: this.labels,
            datasets: this.datasets.map((dataset) => ({
              ...dataset,
              backgroundColor: dataset.borderColor || "rgba(75, 192, 192, 0.2)",
              borderColor: dataset.borderColor || "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            })),
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: {
                beginAtZero: true,
              },
              xAxes: {
                grid: {
                  display: false,
                },
              },
            },
            tooltips: {
              callbacks: {
                title: (tooltipItems, data) => {
                  const index = tooltipItems[0].datasetIndex;
                  return data.datasets[index].label;
                },
                label(tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  const isMoney = dataset.customProps?.isMoney;
                  const value = tooltipItem.value;
                  return `${isMoney ? self.moneyFormat(value) : value}`;
                },
              },
            },
          },
        });
      },
      updateChart() {
        if (this.chartInstance) {
          this.chartInstance.data.labels = this.labels;
          this.chartInstance.data.datasets = this.datasets.map((dataset) => ({
            ...dataset,
            data: dataset.data,
          }));
          this.chartInstance.update();
        } else {
          this.renderChart();
        }
      },
    },
    mounted() {
      this.renderChart();
    },
    beforeDestroy() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
    },
  };
  </script>
  
  <style scoped>
  canvas {
    width: 100%;
    height: 400px;
  }
  </style>
  