import {
  CATALOGS_STORE,
  CATALOGS_DESTROY,
} from './mutation-types';

export default {
  [CATALOGS_STORE](state, data) {
    state.flags = data.flags;
    state.paymentMethods = data.paymentMethods;
    state.businessTaxes = data.businessTaxes;
    state.product = data.product;
    state.order = data.order;
  },
  [CATALOGS_DESTROY](state) {
    state.flags = [];
    state.paymentMethods = [];
    state.businessTaxes = [];
    state.product = {
      categories: [],
    };
    state.order = {
      statuses: [],
      service: {
        categories: [],
      },
    };
  },
};
