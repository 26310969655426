var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center space-x-6"},[_c('div',{staticClass:"relative flex items-center justify-center w-64 h-10 bg-white border-2 border-gray-300 rounded-full p-1 cursor-pointer",on:{"click":_vm.toggle}},[_c('div',{class:[
            'transition-all duration-300 ease-in-out absolute w-1/2 h-full rounded-full',
            {
                'left-0': _vm.currentOption === _vm.option1,
                'left-1/2': _vm.currentOption === _vm.option2
            }
        ],style:({
            background: _vm.selectedBackground
        })}),_c('div',{staticClass:"transition-all duration-300 ease-in-out w-28 h-8 flex items-center justify-center rounded-full absolute left-1",class:{
            'text-white': _vm.currentOption === _vm.option1,
            'text-gray-800': _vm.currentOption !== _vm.option1
        },staticStyle:{"user-select":"none"}},[_c('span',{staticClass:"text-sm font-semibold uppercase"},[_vm._v(_vm._s(_vm.option1))])]),_c('div',{staticClass:"transition-all duration-300 ease-in-out w-28 h-8 flex items-center justify-center rounded-full absolute right-1",class:{
            'text-white': _vm.currentOption === _vm.option2,
            'text-gray-800': _vm.currentOption !== _vm.option2
        },staticStyle:{"user-select":"none"}},[_c('span',{staticClass:"text-sm font-semibold uppercase"},[_vm._v(_vm._s(_vm.option2))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }