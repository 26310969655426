import HttpProxy from '@/services/HttpProxy';
import NotificationService from '@/services/NotificationService';
import ChecklistItemsService from '@/services/ChecklistItemsService';
import CatalogService from "@/services/CatalogService";
import AdminService from "@/services/AdminService";
import BusinessService from "@/services/BusinessService";
import dates from '@/utils/dates';
import store from '@/store';

export default class SignService {
  static membershipLimitReached(membershipEndDate) {
    if (!membershipEndDate) return false;
    const limit = dates.addDays(new Date(membershipEndDate), 0);
    return new Date() >= limit;
  }

  static getAccount(response) {
    return {
      // AUTH
      token: response?.data?.token,

      // ACCOUNT
      id: response?.data?.id || null,
      email: response?.data?.email || null,
      fullname: response?.data?.fullname || null,
      accountType: response?.data?.accountType || null,
      businessId: response?.data?.businessId || null,
      businessName: response?.data?.businessName || null,
      setupCompleted: response?.data?.setupCompleted || false,
      currencySymbol: response?.data?.currencySymbol || '$',
      branchOffices: [], // TO BE MAPPED
      phone: response?.data?.phone || null,

      // MEMBERSHIP
      membershipType: response?.data?.membershipType || null,
      membershipEndDate: response?.data?.membershipEndDate || null,
      membershipLastPay: response?.data?.lastPayment || {
        createdAd: null,
        object: null,
        amount: null,
        currency: null,
        succeeded: false,
      },
      membershipAboutToEnd: response?.data?.membershipAboutToEnd || null,
      membershipLimitReached: this.membershipLimitReached(response?.data?.membershipEndDate),

      // PERMITS
      canViewAdministration: response?.data?.canViewAdministration || false,
      canViewBranchOffices: response?.data.canViewBranchOffices || false,
      canViewStatistics: response?.data.canViewStatistics || false,
      canViewOrders: response?.data.canViewOrders || false,
      canEditOrders: response?.data.canEditOrders || false,
      canEditOrdersPrices: response?.data.canEditOrdersPrices || false,
      canEditStockQuantities: response?.data.canEditStockQuantities || false,
      canCreateOrders: response?.data.canCreateOrders || false,
      canDeleteOrders: response?.data.canDeleteOrders || false,
      canCloseBusiness: response?.data.canCloseBusiness || false,

      // INVOICES
      invoices: response?.data?.invoices || {},

      // FEATURE FLAGS
      featureFlags: response?.data?.featureFlags || [],
    }
  }

  static async register(payload) {
    const response = await HttpProxy.submit({ method: 'post', url: 'sign/register', data: payload });
    if (!response?.data)
      return null;
    return response.data;
  }

  static async login(payload) {
    const response = await HttpProxy.submit({ method: 'post', url: 'sign/login', data: payload });
    if (!response?.data) return null;
    if (response.data.verified) {
      const account = this.getAccount(response);

      store.dispatch('auth/store', { token: account.token });
      store.dispatch('account/store', account);
      store.dispatch('business/read', { phone: account.phone });
      store.dispatch('invoices/store', { config: account.invoices });
      store.dispatch('featureFlags/store', account.featureFlags);
      await NotificationService.get();
      await ChecklistItemsService.get();
      await CatalogService.syncCatalogs();
      await AdminService.syncEmployees();
      await BusinessService.getAll();
      await BusinessService.notifications();
      return response.data;
    } else {
      return { error: 'EmailVerification' };
    }
  }

  static async loadSignedAccount() {
    const response = await HttpProxy.submit({ method: 'get', url: 'sign/current' });
    const business = await HttpProxy.submit({ method: 'get', url: 'BusinessInfo' });

    const account = this.getAccount(response);

    store.dispatch('account/store', account);
    store.dispatch('business/read', business?.data ?? {});
    store.dispatch('invoices/store', { config: account.invoices });
    store.dispatch('featureFlags/store', account.featureFlags);
    await NotificationService.get();
    await ChecklistItemsService.get();
    await CatalogService.syncCatalogs();
    await AdminService.syncEmployees();
    await BusinessService.getAll();
    await BusinessService.notifications();
    return response?.data ?? null;
  }

  static async forgot(data) {
    const response = await HttpProxy.submit({ method: 'post', url: 'sign/request-reset-password', data });
    return response?.data ?? null;
  }

  static async reset(token, data) {
    const response = await HttpProxy.submit({ method: 'post', url: `sign/reset-password/${token}`, data });
    return response?.data ?? null;
  }

  static logout() {
    store.dispatch('auth/logout');
    store.dispatch('account/destroy');
    store.dispatch('business/destroy');
    store.dispatch('notifications/destroy');
    store.dispatch('checklistItems/destroy');
    store.dispatch('invoices/destroy');
    store.dispatch('featureFlags/destroy');
  }
}
