export const CategoryEnum = {
  Phone: 1,
  Tablet: 2,
  Laptop: 3,
  Desktop: 4,
  Wearable: 5,
};

export const CategoryEnumDict = {
  [CategoryEnum.Phone]: 'Teléfono',
  [CategoryEnum.Tablet]: 'Tableta',
  [CategoryEnum.Laptop]: 'Computadora Portátil',
  [CategoryEnum.Desktop]: 'Computadora Escritorio',
  [CategoryEnum.Wearable]: 'Usable',
};

export const MembershipEnum = {
  Basic: 1,
  Professional: 2,
  Premium: 3
};

export const AccountTypeEnum = {
  Owner: 1,
  Technical: 2,
  Biller: 3,
};

export const OrderStamp = {
  Warranty: 1,
  Pay: 2,
};

export const ServiceEnum = {
  MicroWelding: 1,
  ChargingPin: 2,
  Screen: 3,
  Keyboards: 4,
  Cleaning: 5,
  Maintenance: 6,
  Wet: 7,
  Check: 8,
  Battery: 9,
  Other: 10,
};

export const ServiceEnumDict = {
  [ServiceEnum.MicroWelding]: 'Microsoldadura',
  [ServiceEnum.ChargingPin]: 'Pin de carga',
  [ServiceEnum.Screen]: 'Instalación de Pantalla',
  [ServiceEnum.Keyboards]: 'Cambio de Teclados',
  [ServiceEnum.Cleaning]: 'Limpieza / Varios',
  [ServiceEnum.Maintenance]: 'Mantenimiento del Equipo',
  [ServiceEnum.Wet]: 'Equipo Mojado / Varios',
  [ServiceEnum.Check]: 'Revisión de Equipo',
  [ServiceEnum.Battery]: 'Instalación de Bateria',
  [ServiceEnum.Other]: 'Otro',
};

export const AmericanCountries = [
  'Antigua y Barbuda',
  'Argentina',
  'Bahamas',
  'Barbados',
  'Belice',
  'Bolivia',
  'Brasil',
  'Canadá',
  'Chile',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Dominica',
  'Ecuador',
  'El Salvador',
  'Estados Unidos',
  'Granada',
  'Guatemala',
  'Guyana',
  'Haití',
  'Honduras',
  'Jamaica',
  'México',
  'Nicaragua',
  'Panamá',
  'Paraguay',
  'Perú',
  'República Dominicana',
  'San Cristóbal y Nieves',
  'San Vicente y las Granadinas',
  'Santa Lucía',
  'Surinam',
  'Trinidad y Tobago',
  'Uruguay',
  'Venezuela',
];

export const EuropeanCountries = [
  'España',
];

export const tag = {
  br: '%0A',
};

export const PermissionEnum = {
  canViewAdministration: 'CanViewAdministration',
  canViewBranchOffices: 'CanViewBranchOffices',
  canViewStatistics: 'CanViewStatistics',
  canViewOrders: 'CanViewOrders',
  canEditOrders: 'CanEditOrders',
  canEditOrdersPrices: 'CanEditOrdersPrices',
  canEditStockQuantities: 'CanEditStockQuantities',
  canCreateOrders: 'CanCreateOrders',
  canDeleteOrders: 'CanDeleteOrders',
  canCloseBusiness: 'CanCloseBusiness',
};

export const NotificationTypeEnum = {
  Order: 'Order',
  Product: 'Product',
  Expense: 'Expense',
};

export const NotificationConfigEnum = {
  OrderAssigned: 1,
  LowStock: 2,
  OrderExpirationDateForDelivery: 3,
  PaymentDueNotification: 4,
  OrderReadyForDelivery: 5,
  CloseDayWithSummary: 6,
};

export const NotificationConfig = [
  {
    id: NotificationConfigEnum.OrderAssigned,
    label: 'Notificacion cuando se te asigno una orden',
    defaultValue: { email: false, web: false, app: false },
    disabled: { email: false, web: false, app: false },
  },
  {
    id: NotificationConfigEnum.LowStock,
    label: 'Notificacion de stock bajo',
    defaultValue: { email: false, web: false, app: false },
    disabled: { email: false, web: true, app: true },
  },
  {
    id: NotificationConfigEnum.OrderExpirationDateForDelivery,
    label: 'Notificacion de vencimiento de fecha por entregar un orden',
    defaultValue: { email: false, web: false, app: false },
    disabled: { email: false, web: false, app: true },
  },
  {
    id: NotificationConfigEnum.PaymentDueNotification,
    label: 'Notificacion de vencimiento de pago',
    defaultValue: { email: false, web: false, app: false },
    disabled: { email: true, web: false, app: true },
  },
  {
    id: NotificationConfigEnum.OrderReadyForDelivery,
    label: 'Notificacion de orden lista para entregar',
    defaultValue: { email: false, web: false, app: false },
    disabled: { email: false, web: false, app: true },
  },
  {
    id: NotificationConfigEnum.CloseDayWithSummary,
    label: 'Notificacion de cierre de dia con resumen',
    defaultValue: { email: false, web: false, app: false },
    disabled: { email: false, web: true, app: true },
  },
];

export const ContactTypeEnum = {
  Phone: 1,
  Whatsapp: 2,
  Email: 3,
  Other: 4,
};

export const PriorityTypeEnum = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export const PriorityTypeEnumDict = {
  [PriorityTypeEnum.Low]: 'Baja',
  [PriorityTypeEnum.Medium]: 'Media',
  [PriorityTypeEnum.High]: 'Alta',
};

export const PriorityOptions = [
  { value: PriorityTypeEnum.Low, label: 'Baja' },
  { value: PriorityTypeEnum.Medium, label: 'Media' },
  { value: PriorityTypeEnum.High, label: 'Alta' },
];

export const OrderTypesEnum = {
  None: 0,
  Mixed: 1,
  Repairs: 2,
  Sales: 3,
};

export const OrderTypes = [
  { value: OrderTypesEnum.None, label: 'N/A' },
  { value: OrderTypesEnum.Mixed, label: 'Reparaciones/Ventas' },
  { value: OrderTypesEnum.Repairs, label: 'Reparaciones' },
  { value: OrderTypesEnum.Sales, label: 'Ventas' },
];

export const DateFilterTypes = {
  Week: 1,
  Month: 2,
  Year: 3,
  Day: 4,
};

export const DateFilterTypesDict = {
  [DateFilterTypes.Week]: 'Semana',
  [DateFilterTypes.Month]: 'Mes',
  [DateFilterTypes.Year]: 'Año',
  [DateFilterTypes.Day]: 'Dia',
};

export const Translations = {
  [DateFilterTypes.Week]: {
      'Sunday': 'Domingo',
      'Monday': 'Lunes',
      'Tuesday': 'Martes',
      'Wednesday': 'Miércoles',
      'Thursday': 'Jueves',
      'Friday': 'Viernes',
      'Saturday': 'Sábado',
  },
  [DateFilterTypes.Month]: {
      'January': 'Enero',
      'February': 'Febrero',
      'March': 'Marzo',
      'April': 'Abril',
      'May': 'Mayo',
      'June': 'Junio',
      'July': 'Julio',
      'August': 'Agosto',
      'September': 'Septiembre',
      'October': 'Octubre',
      'November': 'Noviembre',
      'December': 'Diciembre',
  },
  [DateFilterTypes.Year]: {
    2022: 2022,
    2023: 2023,
    2024: 2024,
  },
  [DateFilterTypes.Day]: {
    "01": "1AM",
    "02": "2AM",
    "03": "3AM",
    "04": "4AM",
    "05": "5AM",
    "06": "6AM",
    "07": "7AM",
    "08": "8AM",
    "09": "9AM",
    "10": "10AM",
    "11": "11AM",
    "12": "12AM",
    "13": "1PM",
    "14": "2PM",
    "15": "3PM",
    "16": "4PM",
    "17": "5PM",
    "18": "6PM",
    "19": "7PM",
    "20": "8PM",
    "21": "9PM",
    "22": "10PM",
    "23": "11PM",
    "24": "12PM",
  },
};

// Función auxiliar para generar un array de strings de días
function generateDaysArray(start, end) {
  const daysArray = [];
  for (let i = start; i <= end; i++) {
      daysArray.push(i.toString().padStart(2, '0'));
  }
  return daysArray;
}

export const DateFilterCategories = {
  [DateFilterTypes.Week]:
    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  [DateFilterTypes.Month]:
    ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  [DateFilterTypes.Year]:
    [2022, 2023, 2024],
  [DateFilterTypes.Day]: generateDaysArray(1, 23),
};

export const PrintTypes = {
  Letter: 1,
  Thermal: 2,
  Sticker: 3,
};

export const DefaultPrintConfiguration = {
  id: undefined,
  showLogo: true,
  logoX: '100',
  logoY: '100',
  showBusinessName: true,
  showPasscode: true,
};

export const DateFormats = {
  American: "MM/dd/yyyy",
  European: "dd/MM/yyyy",
};

export const OrderStatusIds = {
  PendingAssignment: 'f72e7b04-8ee2-46f6-838a-17e965164690',
  InProgress: '407bdbed-6273-4ea7-90ea-aa5917017f82',
  ToBeDelivered: '540ab1ad-f93d-4e63-b0cd-b5bfa85eb16d',
  Finalized: 'e41a9a3c-4234-4f9f-9b12-50ce624289a6',
  Reject: '10172350-e22f-4cd2-83a1-8a9f31b105da',
  NoSolution: '7e890ec8-1b80-4020-8517-40e2c4e3691a',
  Warranty: 'ee9603b4-cecb-4acf-9626-5ae8dacecc72',
};

export const FileType = {
  Order: 1,
  BusinessInfo: 2,
  PaymentRequestFile: 3,
  OrderPdf: 4,
  OrderSignature: 5,
};

export const ExpenseType = {
  Static: 1,
  Dynamic: 2,
  Product: 3,
};

export const ExpenseTypesDict = {
  [ExpenseType.Static]: 'Fijo',
  [ExpenseType.Dynamic]: 'Variable',
  [ExpenseType.Product]: 'Inventario',
};

export const ExpenseCategory = {
  Salary: 1,
  Rent: 2,
  Services: 3,
  Accounting: 4,
  Marketing: 5,
  Other: 6,
  Product: 7,
};

export const ExpenseCategoryDict = {
  [ExpenseCategory.Salary]: 'Sueldo',
  [ExpenseCategory.Rent]: 'Renta',
  [ExpenseCategory.Services]: 'Servicios',
  [ExpenseCategory.Accounting]: 'Contabilidad',
  [ExpenseCategory.Marketing]: 'Marketing',
  [ExpenseCategory.Other]: 'Otro',
  [ExpenseCategory.Product]: 'Producto',
};

export const ExpenseCategories = [
  { id: ExpenseCategory.Salary, name: 'Sueldo', isStaticCategory: true },
  { id: ExpenseCategory.Rent, name: 'Renta', isStaticCategory: true },
  { id: ExpenseCategory.Services, name: 'Servicios', isStaticCategory: true },
  { id: ExpenseCategory.Accounting, name: 'Contabilidad', isStaticCategory: true },
  { id: ExpenseCategory.Marketing, name: 'Marketing', isStaticCategory: true },
  { id: ExpenseCategory.Other, name: 'Otro', isStaticCategory: false },
];

export const StatisticsFilterType = {
  EntityType: {
    Order: 1,
    Employee: 2,
    Customer: 3,
    Product: 4,
  },
  OrderType: {
    All: 1,
    Service: 2,
    Product: 3,
  },
  EmployeeType: {
    All: 1,
    Individual: 2,
  },
  CustomerType: {
    Top10: 1,
  },
  ProductType: {
    Sold: 1,
    Stock: 2,
  },
};

export const FinancesFilterType = {
  Order: 1,
  Expense: 2,
  Product: 3,
  Utility: 4, // NOT A FILTER ON BE
};

export const FinancesFilterTypeDict = {
  [FinancesFilterType.Order]: 'Ventas',
  [FinancesFilterType.Expense]: 'Gastos',
  [FinancesFilterType.Product]: 'Inventario',
  [FinancesFilterType.Utility]: 'Utilidad',
}

export const FeatureFlagType = {
  CheckAndNotifyLowStockTask: 1,
  CloseDayForBusinessTask: 2,
  CheckAndNotifyExpenseExpirationsTask: 3,
};

export const FeatureFlagTypes = {
  [FeatureFlagType.CheckAndNotifyLowStockTask]: 'Alerta de Stock Bajo: <b>(SAMII-370)</b>',
  [FeatureFlagType.CloseDayForBusinessTask]: 'Cerrar dia: <b>(SAMII-374)</b>',
  [FeatureFlagType.CheckAndNotifyExpenseExpirationsTask]: 'Alerta de Vencimiento de Pago: <b>(SAMII-462)</b>',
};

export const MobileOperatingSystem = {
  Android: 'Android',
  iOS: 'iOS',
};

export const MobileAppRoute = {
  Android: 'https://play.google.com/store/apps/details?id=com.samiimobile',
  iOS: 'https://www.apple.com/mx/',
};

export const ImportExportType = {
  Client: 1,
  Product: 2,
  Finances: 3,
  Statistics: 4,
};

export const CloseBusinessType = {
  Day: 1,
  Week: 2,
  Month: 3,
};

export const ExclusionType = {
  ServiceCategory: 1,
};

export const EnabledWebhookForAccountId = [
  '9cf19842-fe78-445f-9c38-bb3eeaefbaf6', // abel.slash.95@hotmail.com
  'd03bc6ca-700e-46bc-b978-0ec03947e97a', // tabita.fuentes99@gmail.com
  'f8fb5837-b464-4320-87b9-71f45fd9285a', // afuentes95@outlook.es
  'b01563bb-24d9-4820-9606-0aad9c289b23', // gerardoogomez93@gmail.com
];

export const ExpensePendingPaymentId = '4f761463-05ac-42b9-ba76-ba76ebe84ba5';
