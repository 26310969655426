import HttpProxy from '@/services/HttpProxy';

export default class ProductService {
  static async getAll(parameters) {
    const response = await HttpProxy.submit({
      method: 'get',
      url: 'products',
      parameters,
    });
    return response?.data ?? null;
  }

  static async getById(id) {
    const response = await HttpProxy.submit({ method: 'get', url: `products/${id}` });
    return response?.data ?? null;
  }

  static async create({ product }) {
    const response = await HttpProxy.submit({ method: 'post', url: 'products', data: product });
    return response?.data ?? null;
  }

  static async update({ id, product }) {
    const response = await HttpProxy.submit({ method: 'put', url: `products/${id}`, data: product });
    return response?.data ?? null;
  }

  static async delete({ ids }) {
    const response = await HttpProxy.submit({ method: 'post', url: 'products/deleteAllById', data: { ids } });
    return response?.data ?? null;
  }

  static async updateQty({ id, quantity }) {
    const response = await HttpProxy.submit({ method: 'put', url: `products/quantity/${id}`, data: { quantity }});
    return response?.data ?? null;
  }

  static async updatePrice({ productIds, percentageIncrease }) {
    const response = await HttpProxy.submit({ method: 'put', url: 'products/prices', data: { productIds, percentageIncrease }});
    return response?.data ?? null;
  }

  static async updateAlert({ productId, alert }) {
    const response = await HttpProxy.submit({ method: 'put', url: 'products/alert', data: { productId, alert } });
    return response?.data ?? null;
  }
}
