<template>
    <div>
      <div class="w-full h-1 bg-black transform -translate-x-1/2 mt-2 text-center"></div>

      <div class="transform -translate-x-1/2 text-center">
        <div class="text-5xl font-bold text-black">
          +{{ amount }}
        </div>
        <div class="text-lg font-bold text-black">
          {{ title }}
        </div>
        <div class="text-sm text-gray-700">
          {{ description }}
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "Counter",
    props: {
      amount: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true
      }
    }
  };
  </script>
  