<template>
  <div
    :class="['relative flex items-center justify-center text-center cursor-default', boxClass]"
    :style="{
      background: backgroundStyle,
      opacity: isSelected ? 1 : 0.6,
    }"
  >
    <span
      class="text-sm"
      :class="{ 'text-white': isSelected, 'text-gray-400': !isSelected }"
    >
      {{ text }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'GradientRoundedBox',
  props: {
    text: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.isSelected) {
        return `linear-gradient(45deg, #a857f6 0%, #f59911 100%)`;
      } else {
        const colors = ['#fee5ba', '#fdcdcc', '#edd9ff'];
        const color = colors[Math.floor(Math.random() * colors.length)];
        return color;
      }
    },
    boxClass() {
      return [
        'w-44 h-8 rounded-full',
        'transition-all duration-200 ease-in-out',
      ];
    },
  },
};
</script>
