<template>
    <div class="flex items-center justify-center space-x-6">
        <div class="relative flex items-center justify-center w-64 h-10 bg-white border-2 border-gray-300 rounded-full p-1 cursor-pointer"
            @click="toggle">
            <div :class="[
                'transition-all duration-300 ease-in-out absolute w-1/2 h-full rounded-full',
                {
                    'left-0': currentOption === option1,
                    'left-1/2': currentOption === option2
                }
            ]" :style="{
                background: selectedBackground
            }"></div>

            <div :class="{
                'text-white': currentOption === option1,
                'text-gray-800': currentOption !== option1
            }"
                class="transition-all duration-300 ease-in-out w-28 h-8 flex items-center justify-center rounded-full absolute left-1"
                style="user-select: none;">
                <span class="text-sm font-semibold uppercase">{{ option1 }}</span>
            </div>

            <div :class="{
                'text-white': currentOption === option2,
                'text-gray-800': currentOption !== option2
            }"
                class="transition-all duration-300 ease-in-out w-28 h-8 flex items-center justify-center rounded-full absolute right-1"
                style="user-select: none;">
                <span class="text-sm font-semibold uppercase">{{ option2 }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Toggle',
    props: {
        option1: {
            type: String,
            required: true
        },
        option2: {
            type: String,
            required: true
        },
        selectedBackground: {
            type: String,
            default: 'black'
        }
    },
    data() {
        return {
            currentOption: this.option2,
        };
    },
    methods: {
        toggle() {
            this.currentOption = this.currentOption === this.option1 ? this.option2 : this.option1;
            this.$emit('option-changed', this.currentOption);
        }
    }
};
</script>