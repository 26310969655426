import {
  FEATURE_FLAGS_STORE,
  FEATURE_FLAGS_DESTROY,
} from './mutation-types';

export default {
  [FEATURE_FLAGS_STORE](state, data) {
    Object.assign(state, data.sort((a, b) => a.type - b.type));
  },
  [FEATURE_FLAGS_DESTROY](state) {
    Object.assign(state, []);
  },
};
