<template>
    <div>
        <canvas ref="canvas"></canvas>
    </div>
</template>
<style scoped>
canvas {
    width: 100%;
    height: 400px;
}
</style>
<script>
import { Line } from 'vue-chartjs'
import Chart from 'chart.js'

export default {
    extends: Line,
    props: {
        labels: {
            type: Array,
            required: true,
        },
        datasets: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            this.$data._chart = new Chart(this.$refs.canvas, {
                type: 'line',
                data: {
                    labels: this.labels,
                    datasets: this.datasets,
                },
                options: {
                    responsive: true,
                    stacked: false,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        yAxes: this.datasets.map(({ yAxisID, ticks, label, customProps }) => ({
                            type: 'linear',
                            display: true,
                            position: customProps.scalePosition,
                            id: yAxisID,
                            ticks: { suggestedMin: ticks.min, suggestedMax: ticks.max },
                            scaleLabel: { display: true, labelString: label }
                        })),
                    },
                }
            })
        }
    }
}
</script>