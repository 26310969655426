import Vue from 'vue';
import QrcodeVue from "qrcode.vue";
import CountryFlag from 'vue-country-flag';
import SaButton from '@/components/atoms/SaButton';
import SaIconButton from '@/components/atoms/SaIconButton';
import SaCategory from '@/components/atoms/SaCategory';
import SaCheckbox from '@/components/atoms/SaCheckbox';
import SaPhoneCountry from '@/components/atoms/SaPhoneCountry';
import SaEditor from '@/components/atoms/SaEditor';
import SaActionButton from '@/components/atoms/SaActionButton';
import SaGroupActionButton from '@/components/atoms/SaGroupActionButton';
import SaGradientRoundedBox from '@/components/atoms/SaGradientRoundedBox';
import SaToggle from '@/components/atoms/SaToggle';
import SaReview from '@/components/atoms/SaReview';
import SaCounter from '@/components/atoms/SaCounter';
import SaToolbar from '@/components/atoms/SaToolbar';

import Payment from '@/components/dialogs/Payment';
import Service from '@/components/dialogs/Service';
import OrderNotesManager from '@/components/dialogs/OrderNotesManager';
import Category from '@/components/dialogs/Category';
import OrderPayment from '@/components/dialogs/OrderPayment';
import Expense from '@/components/dialogs/Expense';

import MembershipAboutToEnd from '@/components/alerts/MembershipAboutToEnd';

import LockScroll from '@/components/molecules/LockScroll';
import LockScreen from '@/components/molecules/LockScreen';
import QuickStartTip from '@/components/molecules/QuickStartTip';
import CustomTable from '@/components/molecules/CustomTable';

import MultiAxisLineChart from '@/components/charts/MultiAxisLineChart';
import MultiAxisBarChart from '@/components/charts/MultiAxisBarChart';

Vue.component('qr-code', QrcodeVue);
Vue.component('country-flag', CountryFlag);
Vue.component('sa-button', SaButton);
Vue.component('sa-icon-button', SaIconButton);
Vue.component('sa-category', SaCategory);
Vue.component('sa-checkbox', SaCheckbox);
Vue.component('sa-phone-country', SaPhoneCountry);
Vue.component('sa-editor', SaEditor);
Vue.component('sa-action-button', SaActionButton);
Vue.component('sa-group-action-button', SaGroupActionButton);
Vue.component('sa-gradient-rounded-box', SaGradientRoundedBox);
Vue.component('sa-toggle', SaToggle);
Vue.component('sa-review', SaReview);
Vue.component('sa-counter', SaCounter);
Vue.component('sa-toolbar', SaToolbar);

Vue.component('payment', Payment);
Vue.component('service', Service);
Vue.component('order-notes-manager', OrderNotesManager);
Vue.component('category', Category);
Vue.component('order-payment', OrderPayment);
Vue.component('expense', Expense);

Vue.component('membership-about-to-end', MembershipAboutToEnd);
Vue.component('lock-scroll', LockScroll);
Vue.component('lock-screen', LockScreen);
Vue.component('quick-start-tip', QuickStartTip);
Vue.component('custom-table', CustomTable);

Vue.component('multi-axis-line-chart', MultiAxisLineChart);
Vue.component('multi-axis-bar-chart', MultiAxisBarChart);