<template>
    <el-row class="toolbar" type="flex" justify="space-between" align="middle">
        <!-- Botones de acciones -->
        <el-col>
            <el-button type="primary" icon="el-icon-plus" @click="onAdd">Agregar</el-button>
            <el-button type="warning" icon="el-icon-edit" @click="onEdit">Editar</el-button>
            <el-button type="danger" icon="el-icon-delete" @click="onDelete">Eliminar</el-button>
        </el-col>

        <!-- Barra de búsqueda -->
        <el-col>
            <el-input v-model="search" placeholder="Buscar..." prefix-icon="el-icon-search" clearable />
        </el-col>
    </el-row>
</template>

<script>
export default {
    data() {
        return {
            search: "",
        };
    },
    methods: {
        onAdd() {
            this.$message.success("Agregar acción");
        },
        onEdit() {
            this.$message.warning("Editar acción");
        },
        onDelete() {
            this.$confirm("¿Seguro que quieres eliminar?", "Advertencia", {
                confirmButtonText: "Sí",
                cancelButtonText: "No",
                type: "warning",
            }).then(() => {
                this.$message.success("Elemento eliminado");
            });
        },
    },
};
</script>

<style scoped>
.toolbar {
    background: #f5f7fa;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
</style>