<template>
  <div class="bg-white opacity-90 p-4 rounded-lg shadow-md w-64">
    <!-- Estrellas -->
    <div class="justify-center flex space-x-1">
      <svg
        v-for="n in 5"
        :key="n"
        :class="{
          'text-yellow-500': star >= n,
          'text-gray-400': star < n
        }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="20"
        height="20"
        fill="currentColor"
      >
        <path
          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        />
      </svg>
    </div>

    <!-- Texto -->
    <div class="mt-2 text-sm text-gray-700">
      <p>{{ text }}</p>
    </div>

    <!-- Owner -->
    <div class="mt-4 text-sm font-bold text-right">
      {{ owner }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    star: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    owner: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
/* Puedes agregar más estilos si es necesario */
</style>
