<template>
  <div>
    <div>
      <div class="mb-4">
        <span class="text-md font-bold break-words">
          Por favor, selecciona un metodo de pago para crear el gasto
        </span>
      </div>
      <span>Metodo de Pago</span>
      <el-select class="w-full" v-model="value">
        <el-option v-for="paymentMethod in $store.state.catalogs.paymentMethods" :key="paymentMethod.id"
          :label="paymentMethod.name" :value="paymentMethod.id">
          <div class="flex flex-row items-center">
            <span class="ml-2">
              {{ paymentMethod.name }}
            </span>
          </div>
        </el-option>
      </el-select>
    </div>
    <div class="w-full text-right mt-2">
      <el-button size="small" type="primary" @click="savePaymentMethod">Aceptar</el-button>
    </div>
  </div>
</template>
<script>
import error from '@/mixins/error';

export default {
  name: 'PaymentExpenseForm',
  mixins: [error],
  data() {
    return {
      value: null,
    }
  },
  methods: {
    onSelect(paymentMethod) {
      this.value = paymentMethod;
    },
    savePaymentMethod() {
      this.$emit('save:payment', this.value);
      this.value = null;
    }
  }
}
</script>
