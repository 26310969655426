import { EXPENSES_STORE, EXPENSES_DESTROY } from './mutation-types';

export default {
  [EXPENSES_STORE](state, data) {
    state.entities = data.entities;
    state.subcategories = data.subcategories;
  },
  [EXPENSES_DESTROY](state) {
    state.entities = [];
    state.subcategories = [];
  }
};
