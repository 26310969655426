export default {
  flags: [],
  paymentMethods: [],
  businessTaxes: [],
  product: {
    categories: [],
  },
  order: {
    statuses: [],
    service: {
      categories: [],
    },
  },
};
